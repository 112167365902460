import React, { createContext, useState, useContext } from "react";

// Create a context with default value
const PowerShiftableLoadContext = createContext([]);

// Provider component
export const PowerShiftableLoadProvider = ({ children }) => {
  const [powerShiftableLoads, setPowerShiftableLoads] = useState([]);

  const addPowerShiftableLoad = (powerShiftableLoad) => {
    setPowerShiftableLoads([...powerShiftableLoads, powerShiftableLoad]);
  };

  const deletePowerShiftableLoad = (index) => {
    setPowerShiftableLoads(powerShiftableLoads.filter((_, i) => i !== index));
  };

  const updatePowerShiftableLoad = (index, updatedPowerShiftableLoad) => {
    setPowerShiftableLoads(powerShiftableLoads.map((powerShiftableLoad, i) => (i === index ? updatedPowerShiftableLoad : powerShiftableLoad)));
  };

  return (
    <PowerShiftableLoadContext.Provider value={{ powerShiftableLoads, addPowerShiftableLoad, deletePowerShiftableLoad, updatePowerShiftableLoad }}>
      {children}
    </PowerShiftableLoadContext.Provider>
  );
};

// Custom hook to use the Power Shiftable Load context
export const usePowerShiftableLoad = () => {
  return useContext(PowerShiftableLoadContext);
};