import React, { createContext, useState, useContext } from 'react';

const TimeSeriesContext = createContext();

export const TimeSeriesProvider = ({ children }) => {
  const [timeSeriesData, setTimeSeriesData] = useState({
    baseline: '',
  });

  const updateBaseline = (baseline) => {
    setTimeSeriesData((prevData) => ({
      ...prevData,
      baseline,
    }));
  };

  return (
    <TimeSeriesContext.Provider value={{ timeSeriesData, updateBaseline }}>
      {children}
    </TimeSeriesContext.Provider>
  );
};

export const useTimeSeries = () => {
  return useContext(TimeSeriesContext);
};