import React, { useState } from "react";
import { Col, Row, Card, Form } from "@themesberg/react-bootstrap";
import { SwitchTransition, Transition } from "react-transition-group";
import styled from "styled-components";

const FadeDiv = styled.div`
  transition: 0.5s;
  opacity: ${({ state }) => (state === "entered" ? 1 : 0)};
  display: ${({ state }) => (state === "exited" ? "none" : "block")};
`;

const FadeTransition = ({ children, ...rest }) => (
  <Transition {...rest}>
    {state => <FadeDiv state={state}>{children}</FadeDiv>}
  </Transition>
);

export const GridInformationForm = ({ values, setValues, validated }) => {
  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value
    });
  };

  const isGridConnected = values.gridConnection === "gridConnected";

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Grid Connection</h5>
        <Form id="gridInformationForm" noValidate validated={validated}>
          <Form.Group as={Row} className="mb-3">
            <Form.Label as="legend" column sm={4}>
              Grid Connection
            </Form.Label>
            <Col sm={8}>
              <Form.Check
                type="radio"
                label="Grid Connected"
                name="gridConnection"
                value="gridConnected"
                checked={values.gridConnection === "gridConnected"}
                onChange={handleChange}
              />
              <Form.Check
                type="radio"
                label="Islanded Mode"
                name="gridConnection"
                value="islandedMode"
                checked={values.gridConnection === "islandedMode"}
                onChange={handleChange}
              />
            </Col>
          </Form.Group>
          <SwitchTransition mode="out-in">
            <FadeTransition
              key={isGridConnected ? "grid-connected" : "not-connected"}
              timeout={500}
              unmountOnExit
              mountOnEnter
            >
              {isGridConnected && (
                <>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label as="legend" column sm={4}>
                      Always Connected
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Check
                        type="radio"
                        label="Always Connected"
                        name="alwaysConnected"
                        value="alwaysConnected"
                        checked={values.alwaysConnected === "alwaysConnected"}
                        onChange={handleChange}
                      />
                      <Form.Check
                        type="radio"
                        label="Islandable"
                        name="alwaysConnected"
                        value="islandable"
                        checked={values.alwaysConnected === "islandable"}
                        onChange={handleChange}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label as="legend" column sm={4}>
                      Country Tariff *
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Check
                        type="radio"
                        label="Spanish"
                        name="countryTariff"
                        value="spanish"
                        checked={values.countryTariff === "spanish"}
                        onChange={handleChange}
                      />
                      <Form.Check
                        type="radio"
                        label="Portuguese"
                        name="countryTariff"
                        value="portuguese"
                        checked={values.countryTariff === "portuguese"}
                        onChange={handleChange}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Injection to the Grid *</Form.Label>
                    <Form.Select
                      required
                      name="injectionToGrid"
                      value={values.injectionToGrid}
                      onChange={handleChange}
                    >
                      <option value="">Please Select</option>
                      <option value="Never">Never</option>
                      <option value="Always (Register as Generator)">Always (Register as Generator)</option>
                      <option value="Yes, as a Customer ( Only if PV < 100 kW )">Yes, as a Customer ( Only if PV &lt; 100 kW )</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Injection to the Grid is required
                    </Form.Control.Feedback>
                  </Form.Group>
                </>
              )}
            </FadeTransition>
          </SwitchTransition>
        </Form>
      </Card.Body>
    </Card>
  );
};
