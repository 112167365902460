import React, { useState } from 'react';
import { Row, Col, Form, Card } from '@themesberg/react-bootstrap';
import styled from 'styled-components';
import { Transition } from 'react-transition-group';
import TextAreaWithLineNumbers from './TextAreaWithLineNumbers'; // Adjust the import path as necessary

const FadeDiv = styled.div`
  transition: 0.5s;
  opacity: ${({ state }) => (state === "entered" ? 1 : 0)};
  display: ${({ state }) => (state === "exited" ? "none" : "block")};
`;

const FadeTransition = ({ children, ...rest }) => (
  <Transition {...rest}>
    {state => <FadeDiv state={state}>{children}</FadeDiv>}
  </Transition>
);

export const FormsFlexibilityRequests = ({ values, setValues }) => {
  const [scale, setScale] = useState(values.scale || 'Daily');

  const handleScaleChange = (event) => {
    const { value } = event.target;
    setScale(value);
    handleChange(event);

    // Clear days and months when switching to Yearly
    if (value === 'Yearly') {
      setValues((prevValues) => ({
        ...prevValues,
        days: [],
        months: []
      }));
    }
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    if (type === 'checkbox') {
      setValues((prevValues) => ({
        ...prevValues,
        [name]: checked
          ? [...prevValues[name], value]
          : prevValues[name].filter((v) => v !== value),
      }));
    } else {
      setValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  const handleBlur = (event) => {
    const { name, value, placeholder } = event.target;

    if (name === 'price' && value !== '') {
      setValues((prevValues) => ({
        ...prevValues,
        [name]: `${value} ${placeholder}`
      }));
    }
  };

  const handleFocus = (event) => {
    const { name, value } = event.target;

    const newValue = value.split(' ')[0];
    setValues((prevValues) => ({
      ...prevValues,
      [name]: newValue
    }));
  };

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <Form id="flexibilityRequestsForm" noValidate>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  value={values.name}
                  onChange={handleChange}
                  name="name"
                  placeholder="Enter name"
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Scale</Form.Label>
                <Form.Select
                  value={scale}
                  onChange={handleScaleChange}
                  name="scale"
                >
                  <option value="Daily">Daily</option>
                  <option value="Yearly">Yearly</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <FadeTransition in={scale === 'Daily'}>
            <div>
              <Row>
                <Col md={12} className="mb-3">
                  <Form.Group>
                    <Form.Label>Days</Form.Label>
                    <div>
                      {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day, index) => (
                        <Form.Check
                          inline
                          label={day}
                          type="checkbox"
                          name="days"
                          value={day}
                          checked={values.days.includes(day)}
                          onChange={handleChange}
                          key={index}
                        />
                      ))}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={12} className="mb-3">
                  <Form.Group>
                    <Form.Label>Months</Form.Label>
                    <div>
                      {['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].map((month, index) => (
                        <Form.Check
                          inline
                          label={month}
                          type="checkbox"
                          name="months"
                          value={month}
                          checked={values.months.includes(month)}
                          onChange={handleChange}
                          key={index}
                        />
                      ))}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </FadeTransition>
          <FadeTransition in={scale === 'Yearly'}>
            <div>
              {/* Yearly specific fields can be added here if needed */}
            </div>
          </FadeTransition>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Metric</Form.Label>
                <Form.Select
                  value={values.metric}
                  onChange={handleChange}
                  name="metric"
                >
                  <option value="%">%</option>
                  <option value="kW">kW</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Photoperiod</Form.Label>
                <Form.Select
                  value={values.photoperiod}
                  onChange={handleChange}
                  name="photoperiod"
                >
                  <option value="Day">Day</option>
                  <option value="Night">Night</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Price</Form.Label>
                <Form.Control
                  type="text"
                  value={values.price}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  name="price"
                  placeholder="€"
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Power Variance</Form.Label>
                <Form.Select
                  value={values.power_variance}
                  onChange={handleChange}
                  name="power_variance"
                >
                  <option value="power_limited">Power Limited</option>
                  <option value="power_varied">Power Varied</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Measurement Ref</Form.Label>
                <Form.Select
                  value={values.measurement_ref}
                  onChange={handleChange}
                  name="measurement_ref"
                >
                  <option value="punto_conexion_red">Punto Conexion Red</option>
                  <option value="cargas_flexibles">Cargas Flexibles</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Request Type</Form.Label>
                <Form.Select
                  value={values.request_type}
                  onChange={handleChange}
                  name="request_type"
                >
                  <option value="+">+</option>
                  <option value="-">-</option>
                  <option value="=">=</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mb-3">
              <Form.Group>
                <Form.Label>Availability</Form.Label>
                <TextAreaWithLineNumbers
                  value={values.K_r || ''}
                  onChange={handleChange}
                  placeholder="Enter Availability data"
                  name="K_r"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mb-3">
              <Form.Group>
                <Form.Label>Power</Form.Label>
                <TextAreaWithLineNumbers
                  value={values.P_r || ''}
                  onChange={handleChange}
                  placeholder="Enter Power data"
                  name="P_r"
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};