import React, { createContext, useState, useEffect } from 'react';

export const GlobalStateContext = createContext();

export const GlobalStateProvider = ({ children }) => {
  const [globalState, setGlobalState] = useState(() => {
    const savedState = localStorage.getItem('globalState');
    return savedState ? JSON.parse(savedState) : {
      clientAddressData: {
        firstName: "",
        lastName: "",
        cif: "",
        phone: "",
        email: "",
        address: "",
        addressNumber: "",
        city: "",
        state: "",
        zip: "",
      },
      commercialAddressData: {
        firstName: "",
        cif: "",
        email: "",
        phone: "",
        address: "",
        addressNumber: "",
        city: "",
        state: "",
        zip: "",
      },
      projectFinancials: {
        cashChange: '',
        operationCost: '',
        discountRate: '',
        projectLifetime: '',
        inflationRate: '',
        priceCorrectionFactor: '',
        year: '',
      },
      emissionsCost: {
        costCO: '',
        costHC: '',
        costParticles: '',
        costNOx: '',
        costCO2: '',
      },

      gridConnectionData: {
        gridConnection: '',
        alwaysConnected: '',
        countryTariff: '',
        injectionToGrid: '',
      },
      powerInformationData: {
        tariff: '',
        meter: '',
        period1: '',
        period2: '',
        period3: '',
        period4: '',
        period5: '',
        period6: '',
      },
      energySellingData: {
        priceOption: '',
        fixedPrice: '',
        period1: '',
        period2: '',
        period3: '',
        period4: '',
        period5: '',
        period6: '',
        annualPrice: '',
      },
      energyBuyingData: {
        priceOption: '',
        fixedPrice: '',
        period1: '',
        period2: '',
        period3: '',
        period4: '',
        period5: '',
        period6: '',
        annualPrice: '',
      },
      economicConstraints:{ 
        roiOperator: '',
        roiValue: '',
        paybackOperator: '',
        paybackValue: '',
        investmentOperator: '',
        investmentValue: ''
      },
      renewableShareConstraints: {
        renewableShare: ''
      },
      request_id: '',

    };
  });

  useEffect(() => {
    localStorage.setItem('globalState', JSON.stringify(globalState));
  }, [globalState]);

  const updateGridConnectionData = (data) => {
    setGlobalState((prevState) => ({
      ...prevState,
      gridConnectionData: {
        ...prevState.gridConnectionData,
        ...data,
      },
    }));
  };

  const updatePowerInformationData = (data) => {
    setGlobalState((prevState) => ({
      ...prevState,
      powerInformationData: {
        ...prevState.powerInformationData,
        ...data,
      },
    }));
  };

  const updateEnergySellingData = (data) => {
    setGlobalState((prevState) => ({
      ...prevState,
      energySellingData: {
        ...prevState.energySellingData,
        ...data,
      },
    }));
  };

  const updateEnergyBuyingData = (data) => {
    setGlobalState((prevState) => ({
      ...prevState,
      energyBuyingData: {
        ...prevState.energyBuyingData,
        ...data,
      },
    }));
  };

  return (
    <GlobalStateContext.Provider value={{ globalState, setGlobalState, updateGridConnectionData, updatePowerInformationData, updateEnergySellingData, updateEnergyBuyingData }}>
      {children}
    </GlobalStateContext.Provider>
  );
};