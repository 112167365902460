// context/PVContext.js
import React, { createContext, useState, useContext } from "react";

// Create a context with default value
const PVContext = createContext([]);

// Provider component
export const PVProvider = ({ children }) => {
  const [pvSystems, setPvSystems] = useState([]);

  const addPvSystem = (pvSystem) => {
    setPvSystems([...pvSystems, pvSystem]);
  };

  const deletePvSystem = (index) => {
    setPvSystems(pvSystems.filter((_, i) => i !== index));
  };

  const updatePvSystem = (index, updatedPvSystem) => {
    setPvSystems(pvSystems.map((pvSystem, i) => (i === index ? updatedPvSystem : pvSystem)));
  };

  return (
    <PVContext.Provider value={{ pvSystems, addPvSystem, deletePvSystem, updatePvSystem }}>
      {children}
    </PVContext.Provider>
  );
};

// Custom hook to use the PV context
export const usePV = () => {
  return useContext(PVContext);
};