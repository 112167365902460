import React from "react";
import { Col, Row, Card, Form } from "@themesberg/react-bootstrap";
import { generatorModels } from "../data/models-generator";

export const GENERATORForm = ({ values, setValues, validated }) => {
  const handleChange = (event) => {
    const { name, value } = event.target;

    // Perform regex check to ensure the value is a valid number or empty
    const isValidNumber = value === '' || /^\s*(?=.*[0-9])\d*(?:[.,]\d*)?\s*$/.test(value);

    // Update state only if the value is valid or the field is not the combobox
    if (isValidNumber || name === "newGeneratorCanBeAdded" || name === "model" || name === "name" || name === "generatorUse") {
      setValues({
        ...values,
        [name]: value
      });
    }
  };

  const handleBlur = (event) => {
    const { name, value, placeholder } = event.target;

    // Perform regex check to ensure the value is a valid number or empty
    const isValidNumber = value === '' || /^\s*(?=.*[0-9])\d*(?:[.,]\d*)?\s*$/.test(value);

    if (isValidNumber && value !== '') {
      setValues({
        ...values,
        [name]: `${value} ${placeholder}`
      });
    } else if (!isValidNumber && (name === "newGeneratorCanBeAdded" || name === "model" || name === "name" || name === "generatorUse")) {
      setValues({
        ...values,
        [name]: value
      });
    } else if (!isValidNumber) {
      setValues({
        ...values,
        [name]: ''
      });
    }
  };

  const handleFocus = (event) => {
    const { name, value } = event.target;
    
    // Remove any appended placeholder text
    const newValue = value.split(' ')[0];
    setValues({
      ...values,
      [name]: newValue
    });
  };

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <Form id="generatorForm" noValidate validated={validated}>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Name *</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Generator System"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">
                  Name is required
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Number of Generators already in the system *</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="units"
                  name="generatorInstalledCapacity"
                  value={values.generatorInstalledCapacity}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                />
                <Form.Control.Feedback type="invalid">
                  Generator Installed Capacity is required
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Model *</Form.Label>
                <Form.Select
                  required
                  name="model"
                  value={values.model}
                  onChange={handleChange}
                >
                  <option value="">No Generator Selected</option>
                  {generatorModels.map((model, index) => (
                    <option key={index} value={model}>{model}</option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Model is required
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Generator Install Capacity to Add (Assuming Fixed)</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="kWh"
                  name="generatorInstallCapacityToAdd"
                  value={values.generatorInstallCapacityToAdd}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                />
                <Form.Control.Feedback type="invalid">
                  Generator Install Capacity to Add is required
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>New Generators can be added? *</Form.Label>
                <Form.Select
                  required
                  name="newGeneratorCanBeAdded"
                  value={values.newGeneratorCanBeAdded}
                  onChange={handleChange}
                >
                  <option value="">Please Select</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  New Generators can be added? is required
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Fixed Incentive *</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="€/kWh"
                  name="fixedIncentive"
                  value={values.fixedIncentive}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                />
                <Form.Control.Feedback type="invalid">
                  Fixed Incentive is required
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Generator Use *</Form.Label>
                <Form.Select
                  required
                  name="generatorUse"
                  value={values.generatorUse}
                  onChange={handleChange}
                >
                  <option value="">Please Select</option>
                  <option value="Base">Base</option>
                  <option value="Emergency">Emergency</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Generator Use is required
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};
