import React from 'react';
import { Card, Table, Dropdown, ButtonGroup, Button } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons';
import { useBESS } from '../context/BESSContext'; // Import the custom hook

export const BESSTable = ({ handleShowModal }) => { // Pass handleShowModal as a prop
  const { bessSystems, deleteBessSystem } = useBESS(); // Get BESS systems and delete function from the context

  const TableRow = (props) => {
    const { index, name, model, newBessCanBeAdded, bessInstalledCapacity, bessInstallCapacityToAdd, fixedIncentive } = props;

    const handleRemove = () => {
      deleteBessSystem(index); // Call the delete function with the index
    };

    const handleEdit = () => {
      handleShowModal("+ Battery / Energy Storage", { index, ...props }); // Pass the index and data to the modal
    };

    return (
      <tr style={{ height: '50px' }}>
        <td className="border-0">
          <Dropdown as={ButtonGroup} alignRight>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="h6">{name || ''}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={handleEdit}>
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
              </Dropdown.Item>
              <Dropdown.Item className="text-danger" onClick={handleRemove}>
                <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
        <td className="border-0">{model || ''}</td>
        <td className="border-0">{bessInstalledCapacity || ''}</td>
        <td className="border-0">{bessInstallCapacityToAdd || ''}</td>
        <td className="border-0">{newBessCanBeAdded || ''}</td>
        <td className="border-0">{fixedIncentive || ''}</td>
      </tr>
    );
  };

  const rowsToFill = 6;
  const currentRows = bessSystems.length;
  const placeholdersNeeded = rowsToFill - currentRows > 0 ? rowsToFill - currentRows : 0;

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body className="pb-0">
        <Table responsive className="table-centered table-nowrap rounded mb-0">
          <thead className="thead-light">
            <tr>
              <th className="border-0">Name</th>
              <th className="border-0">Model</th>
              <th className="border-0">Installed Capacity</th>
              <th className="border-0">Capacity to Add</th>
              <th className="border-0">New BESS Can Be Added</th>
              <th className="border-0">Fixed Incentive</th>
            </tr>
          </thead>
          <tbody>
            {bessSystems.length > 0 ? (
              <>
                {bessSystems.map((bessSystem, index) => (
                  <TableRow key={index} index={index} {...bessSystem} />
                ))}
                {Array.from({ length: placeholdersNeeded }).map((_, idx) => (
                  <tr key={`placeholder-${idx}`} style={{ height: '50px' }}>
                    <td className="border-0" colSpan="6"></td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td className="text-center" colSpan="6" style={{ height: '300px' }}>
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};