import React from "react";
import { Card, Form, Row, Col } from "@themesberg/react-bootstrap";

const EconomicConstraintsForm = ({ values, setValues }) => {
  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value
    });
  };

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Economic Constraints</h5>
        <Form>
          <Row className="align-items-center mb-3">
            <Col md={3}>
              <Form.Group>
                <Form.Label>ROI</Form.Label>
                <Form.Select name="roiOperator" value={values.roiOperator} onChange={handleChange}>
                  <option value="">Select</option>
                  <option value="leq">≤</option>
                  <option value="geq">≥</option>
                  <option value="eq">=</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={9}>
              <Form.Group>
                <Form.Label>&nbsp;</Form.Label> {/* This label is used to align the input field */}
                <Form.Control type="text" name="roiValue" value={values.roiValue} onChange={handleChange} placeholder="%" />
              </Form.Group>
            </Col>
          </Row>
          <Row className="align-items-center mb-3">
            <Col md={3}>
              <Form.Group>
                <Form.Label>Payback</Form.Label>
                <Form.Select name="paybackOperator" value={values.paybackOperator} onChange={handleChange}>
                  <option value="">Select</option>
                  <option value="leq">≤</option>
                  <option value="geq">≥</option>
                  <option value="eq">=</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={9}>
              <Form.Group>
                <Form.Label>&nbsp;</Form.Label> {/* This label is used to align the input field */}
                <Form.Control type="text" name="paybackValue" value={values.paybackValue} onChange={handleChange} placeholder="years" />
              </Form.Group>
            </Col>
          </Row>
          <Row className="align-items-center mb-3">
            <Col md={3}>
              <Form.Group>
                <Form.Label>Investment</Form.Label>
                <Form.Select name="investmentOperator" value={values.investmentOperator} onChange={handleChange}>
                <option value="">Select</option>
                  <option value="leq">≤</option>
                  <option value="geq">≥</option>
                  <option value="eq">=</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={9}>
              <Form.Group>
                <Form.Label>&nbsp;</Form.Label> {/* This label is used to align the input field */}
                <Form.Control type="text" name="investmentValue" value={values.investmentValue} onChange={handleChange} placeholder="k€" />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default EconomicConstraintsForm;