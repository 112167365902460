import React from 'react';
import { Row, Col, Form } from '@themesberg/react-bootstrap';
import TextAreaWithLineNumbers from './TextAreaWithLineNumbers'; // Adjust the import path as necessary

const ProfileBaselineForm = ({ values, handleChange, handleBlur, handleFocus }) => {
  return (
    <Form noValidate>
      <Row>
        <Col md={12} className="mb-3">
          <Form.Group>
            <Form.Label>Time Series Data</Form.Label>
            <TextAreaWithLineNumbers
              value={values.baseline || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              placeholder="Copy and Paste Time Series Data Here"
              name="baseline"
            />
            <Form.Control.Feedback type="invalid">
              PV time series data is required
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};

export default ProfileBaselineForm;