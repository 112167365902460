// pages/microgrid/Renewables.js
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Button, Modal, Alert } from "@themesberg/react-bootstrap";



import { CriticalLoadsTable } from "../../components/Tables-criticalLoads";
import { DisconnectableLoadsTable } from "../../components/Tables-disconnectableLoads";
import { ReducibleLoadsTable } from "../../components/Tables-reducibleLoads";
import { PowerShiftableTable } from "../../components/Tables-powerShiftable";
import { TimeShiftableTable} from "../../components/Tables-timeShiftableLoads";

import { GENERATORTable } from "../../components/Tables-generator";



import { FormsLoadCritical } from "../../components/Forms-load-critical";
import { FormsLoadDisconnectable } from "../../components/Forms-load-disconnectable";
import { FormsLoadReducible } from "../../components/Forms-load-reducible";
import { FormsPowerShiftable } from "../../components/Forms-load-powerShiftable";


import { GENERATORForm } from "../../components/Forms-generator";



import { useCriticalLoad } from "../../context/Loads_CriticalContext"; // Import the custom hook
import { useDisconnectableLoad } from "../../context/Loads_DisconnectableContext";
import { useReducibleLoad } from "../../context/Loads_ReducibleContext";
import { usePowerShiftableLoad } from "../../context/Loads_PowerShiftableContext";
import { useTimeShiftableLoad } from "../../context/Loads_TimeShiftableContext";


import axios from "axios"; // ADD
import { useHistory } from "react-router-dom"; // ADD
import { ThreeDots } from "react-loader-spinner"; // ADD
import "./Renewables.css";
import { FormsLoadTimeShiftable } from "../../components/Forms-load-timeShiftable";

export default () => {
  // Contexts
  const API_URL = process.env.REACT_APP_API_URL;

  const { criticalLoads, addCriticalLoad, updateCriticalLoad } = useCriticalLoad();
  const { disconnectableLoads, addDisconnectableLoad, updateDisconnectableLoad } = useDisconnectableLoad(); // Include updateBessSystem
  const { reducibleLoads, addReducibleLoad, updateReducibleLoad } = useReducibleLoad(); // Include updateBessSystem
  const { powerShiftableLoads, addPowerShiftableLoad, updatePowerShiftableLoad } = usePowerShiftableLoad(); // Include updateBessSystem
  const { timeShiftableLoads, addTimeShiftableLoad, updateTimeShiftableLoad } = useTimeShiftableLoad(); // Include updateTimeShiftableLoad


  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");



  const [validated, setValidated] = useState(false);
  const [validated_disconnectable, setValidated_disconnectable] = useState(false);
  const [validated_reducible, setValidated_reducible] = useState(false);
  const [validated_powershiftable, setValidated_powershiftable] = useState(false);
  const [validated_timeShiftable, setValidated_timeShiftable] = useState(false);




  const [errorMessage, setErrorMessage] = useState(""); // ADD
  const [successMessage, setSuccessMessage] = useState(""); // ADD
  const [loading, setLoading] = useState(false); // ADD
  const history = useHistory(); // ADD

  const [values_criticalLoads, setValues_criticalLoads] = useState({
    name: '',
    scale: 'Daily',
    days: [],
    months: [],
    metric: '%',
    photoperiod: 'Day',
    supply_source: 'PV',
    islandable: 'Yes',
    demand: ''
  });

  const [values_disconnectableLoads, setValues_disconnectableLoads] = useState({
    name: '',
    days: [],
    months: [],
    scale: 'Daily',
    metric: '%',
    photoperiod: 'Day',
    flexibility_cost: '',
    is_flex_request: 'No',
    file: null,
    D_Md: ''
});


    const [values_reducible, setValues_reducible] = useState({
      name: '',
      days: [],
      months: [],
      scale: 'Daily',
      metric: '%',
      photoperiod: 'Day',
      flexibility_cost: '',
      is_flex_request: 'No',
      D_Mr: '',
      Pmin_Mr: '',
      c_Mr: ''
    });

    const [values_powershiftable, setValues_powershiftable] = useState({
      name: '',
      days: [],
      months: [],
      scale: 'Daily',
      metric: '%',
      photoperiod: 'Day',
      flexibility_cost: '',
      is_flex_request: 'No',
      total_energy: '',
      K_Mf: '',
      Pmax_Mf: '',
      Pmin_Mf: '',
      P_Mf: '',
      c_inc_Mf: '',
      c_red_Mf: '',
      powerOption: 'Total Energy'
    });

    const [values_timeShiftable, setValues_timeShiftable] = useState({
      name: '',
      days: [],
      months: [],
      scale: 'Daily',
      metric: '%',
      photoperiod: 'Day',
      flexibility_cost: '',
      is_flex_request: 'No',
      duration: '',
      D_Ms: '',
      K_Ms: ''
    });

  const [editIndex, setEditIndex] = useState(null); // Track the index of the item being edited

  const handleShowModal = (title, data) => {
    setModalTitle(title);
    if (data) {
      setEditIndex(data.index);
      if (title === "+ Critical") {
        setValues_criticalLoads(data);
      } else if (title === "+ Disconnectable") {
        setValues_disconnectableLoads(data);
      } else if (title === "+ Reducible") {
        setValues_reducible(data);
      } else if (title === "+ Power Shiftable") {
        setValues_powershiftable(data);
      }
      else if (title === "+ Time Shiftable") {
        setValues_timeShiftable(data);
      }
    }
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEditIndex(null);
    setValues_criticalLoads({
      name: '',
      scale: 'Daily',
      days: [],
      months: [],
      metric: '%',
      photoperiod: 'Day',
      supply_source: 'PV',
      islandable: 'Yes',
      demand: ''
    });
    setValues_disconnectableLoads({
      name: '',
      days: [],
      months: [],
      scale: 'Daily',
      metric: '%',
      photoperiod: 'Day',
      flexibility_cost: '',
      is_flex_request: 'No',
      file: null,
      D_Md: ''
    });
    setValues_reducible({
      name: '',
      days: [],
      months: [],
      scale: 'Daily',
      metric: '%',
      photoperiod: 'Day',
      flexibility_cost: '',
      is_flex_request: 'No',
      D_Mr: '',
      Pmin_Mr: '',
      c_Mr: '',
    });
    setValues_powershiftable({
      name: '',
      days: [],
      months: [],
      scale: 'Daily',
      metric: '%',
      photoperiod: 'Day',
      flexibility_cost: '',
      is_flex_request: 'No',
      total_energy: '',
      K_Mf: '',
      Pmax_Mf: '',
      Pmin_Mf: '',
      P_Mf: '',
      c_inc_Mf: '',
      c_red_Mf: '',
      powerOption: 'Total Energy'
    });

    setValues_timeShiftable({
      name: '',
      days: [],
      months: [],
      scale: 'Daily',
      metric: '%',
      photoperiod: 'Day',
      flexibility_cost: '',
      is_flex_request: 'No',
      duration: '',
      D_Ms: '',
      K_Ms: ''
    });
  };

  const handleSave = () => {
    if (modalTitle === "+ Critical") {
      handleSave_critical();
    } else if (modalTitle === "+ Disconnectable") {
      handleSave_disconnectable();
    } else if (modalTitle === "+ Reducible") {
      handleSave_reducible();
    }    
    else if (modalTitle === "+ Power Shiftable") {
      handleSave_powershiftable();
    }
      else if (modalTitle === "+ Time Shiftable") {
      handleSave_timeShiftable();
    }
  };

  const handleSave_critical = () => {
    const form = document.querySelector("#loadCriticalForm");
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      if (editIndex !== null) {
        updateCriticalLoad(editIndex, values_criticalLoads); // Critical Load
      } else {
        addCriticalLoad(values_criticalLoads); // Critical Load
      }
      console.log("Critical Load saved: ", values_criticalLoads);
      setValues_criticalLoads({
        name: '',
        scale: 'Daily',
        days: [],
        months: [],
        metric: '%',
        photoperiod: 'Day',
        supply_source: 'PV',
        islandable: 'Yes',
        demand: ''
      });
      setShowModal(false);
      setValidated(false);
      setEditIndex(null);
    }
  };

  const handleSave_disconnectable = () => {
    const form = document.querySelector("#loadDisconnectableForm");
    if (form.checkValidity() === false) {
      setValidated_disconnectable(true);
    } else {
      if (editIndex !== null) {
        updateDisconnectableLoad(editIndex, values_disconnectableLoads); // Update the existing BESS system
      } else {
        addDisconnectableLoad(values_disconnectableLoads); // Add a new BESS system
      }
      console.log("disconnectable load: ", values_disconnectableLoads);
      setValues_disconnectableLoads({
        name: '',
        days: [],
        months: [],
        scale: 'Daily',
        metric: '%',
        photoperiod: 'Day',
        flexibility_cost: '',
        is_flex_request: 'No',
        file: null,
        D_Md: ''
      });
      setShowModal(false);
      setValidated_disconnectable(false);
      setEditIndex(null);
    }
  };

  const handleSave_reducible = () => {
    const form = document.querySelector("#loadReducibleForm");
    if (form.checkValidity() === false) {
      setValidated_reducible(true);
    } else {
      if (editIndex !== null) {
        updateReducibleLoad(editIndex, values_reducible); // Update the existing BESS system
      } else {
        addReducibleLoad(values_reducible); // Add a new BESS system
      }
      console.log("reducible load: ", values_reducible);
      setValues_reducible({
        name: '',
        days: [],
        months: [],
        scale: 'Daily',
        metric: '%',
        photoperiod: 'Day',
        flexibility_cost: '',
        is_flex_request: 'No',
        D_Mr: '',
        Pmin_Mr: '',
        c_Mr: ''
      });
      setShowModal(false);
      setValidated_reducible(false);
      setEditIndex(null);
    }
  };


  const handleSave_powershiftable = () => {
    const form = document.querySelector("#loadPowerShiftableForm");
    if (form.checkValidity() === false) {
      setValidated_reducible(true);
    } else {
      if (editIndex !== null) {
        updatePowerShiftableLoad(editIndex, values_powershiftable); // Update the existing BESS system
      } else {
        addPowerShiftableLoad(values_powershiftable); // Add a new BESS system
      }
      console.log("powershiftable load: ", values_powershiftable);
      setValues_powershiftable({
        name: '',
        days: [],
        months: [],
        scale: 'Daily',
        metric: '%',
        photoperiod: 'Day',
        flexibility_cost: '',
        is_flex_request: 'No',
        total_energy: '',
        K_Mf: '',
        Pmax_Mf: '',
        Pmin_Mf: '',
        P_Mf: '',
        c_inc_Mf: '',
        c_red_Mf: '',
        powerOption: 'Total Energy'
      });
      setShowModal(false);
      setValidated_powershiftable(false);
      setEditIndex(null);
    }
  };






  const handleSave_timeShiftable = () => {
    const form = document.querySelector("#loadTimeShiftableForm");
    if (form.checkValidity() === false) {
      setValidated_timeShiftable(true);
    } else {
      if (editIndex !== null) {
        updateTimeShiftableLoad(editIndex, values_timeShiftable); // Update the existing Generator system
      } else {
        addTimeShiftableLoad(values_timeShiftable); // Add a new Generator system
      }
      console.log("Time Shiftable: ", values_timeShiftable);
      setValues_timeShiftable({
        name: '',
        days: [],
        months: [],
        scale: 'Daily',
        metric: '%',
        photoperiod: 'Day',
        flexibility_cost: '',
        is_flex_request: 'No',
        duration: '',
        D_Ms: '',
        K_Ms: '',
      });
      setShowModal(false);
      setValidated_timeShiftable(false);
      setEditIndex(null);
    }
  };

  const handleRunClick = async () => {
    // Log the load data
    console.log('criticalLoads:', JSON.stringify(criticalLoads, null, 2));
    console.log('disconnectableLoads:', JSON.stringify(disconnectableLoads, null, 2));
    console.log('reducibleLoads:', JSON.stringify(reducibleLoads, null, 2));
    console.log('powerShiftableLoads:', JSON.stringify(powerShiftableLoads, null, 2));
    console.log('timeShiftableLoads:', JSON.stringify(timeShiftableLoads, null, 2));
  
    // Prepare the data payload
    const data = {
      criticalLoads,
      disconnectableLoads,
      reducibleLoads,
      powerShiftableLoads,
      timeShiftableLoads,
    };
    console.log('Saved Data:', JSON.stringify(data, null, 2));
  
    // Clear any previous error messages and start loading
    setErrorMessage("");
    setLoading(true);
  
    // Dynamically determine the API URL based on environment
    const API_URL = process.env.REACT_APP_API_URL || "http://127.0.0.1:5000";
  
    try {
      // Send the data to the server
      const response = await axios.post(`${API_URL}/load_chamber_any_json`, data, {
        timeout: 5000, // 5 seconds timeout
      });
  
      if (response.status === 200) {
        console.log("Data successfully sent to the server.");
        setSuccessMessage("Data successfully saved.");
        setTimeout(() => {
          setSuccessMessage("");
          setLoading(false);
          history.push("/grid-connection"); // Navigate to the Loads route
        }, 3000);
      } else {
        throw new Error("Server not responsive");
      }
    } catch (error) {
      console.error("Error occurred:", error.message);
      setErrorMessage("Server not responsive");
      setLoading(false);
    } finally {
      // Clear the error message after a timeout
      setTimeout(() => setErrorMessage(""), 3000);
    }
  };

  return (
    <>
      {loading && ( 
        <div className="loading-overlay"> 
          <ThreeDots color="#00BFFF" height={80} width={80} /> 
        </div> 
      )}
      <Row className="align-items-center mt-4 mb-4">
        <Col>
          <h4>Flexible Loads</h4>
        </Col>
        <Col className="text-right">
          <Button
            variant="success"
            type="button"
            className="me-2"
            onClick={handleRunClick}
            disabled={loading} // Disable button while loading
          >
            <FontAwesomeIcon icon={faArrowRight} className="me-2" />
            <span>Next</span>
          </Button>
        </Col>
      </Row>
      {errorMessage && (
        <Row>
          <Col>
            <Alert variant="danger">{errorMessage}</Alert>
          </Col>
        </Row>
      )}
      {successMessage && (
        <Row>
          <Col>
            <Alert variant="success">{successMessage}</Alert>
          </Col>
        </Row>
      )}

      <Row className="align-items-center mt-4 mb-4">
        <Col>
          <h4 className="centered-heading">Critical</h4>
        </Col>
        <Col xs="auto">
          <Button
            variant="secondary"
            type="button"
            className="text-dark me-2"
            onClick={() => handleShowModal("+ Critical")}
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            <span>New</span>
          </Button>
        </Col>
      </Row>
      <CriticalLoadsTable className="mb-4" handleShowModal={handleShowModal} />




      <Row className="align-items-center mt-4 mb-4">
        <Col>
          <h4 className="centered-heading">Disconnectable</h4>
        </Col>
        <Col xs="auto">
          <Button
            variant="secondary"
            className="text-dark me-2"
            onClick={() => handleShowModal("+ Disconnectable")}
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            <span>New</span>
          </Button>
        </Col>
      </Row>
      <DisconnectableLoadsTable className="mb-4" handleShowModal={handleShowModal} />


      <Row className="align-items-center mt-4 mb-4">
        <Col>
          <h4 className="centered-heading">Reducible</h4>
        </Col>
        <Col xs="auto">
          <Button
            variant="secondary"
            className="text-dark me-2"
            onClick={() => handleShowModal("+ Reducible")}
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            <span>New</span>
          </Button>
        </Col>
      </Row>
      <ReducibleLoadsTable className="mb-4" handleShowModal={handleShowModal} />

      <Row className="align-items-center mt-4 mb-4">
        <Col>
          <h4 className="centered-heading">Power Shiftable</h4>
        </Col>
        <Col xs="auto">
          <Button
            variant="secondary"
            className="text-dark me-2"
            onClick={() => handleShowModal("+ Power Shiftable")}
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            <span>New</span>
          </Button>
        </Col>
      </Row>
      <PowerShiftableTable className="mb-4" handleShowModal={handleShowModal} />


      <Row className="align-items-center mt-4 mb-4">
        <Col>
          <h4 className="centered-heading">Time Shiftable</h4>
        </Col>
        <Col xs="auto">
          <Button
            variant="secondary"
            className="text-dark me-2"
            onClick={() => handleShowModal("+ Time Shiftable")}
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            <span>New</span>
          </Button>
        </Col>
      </Row>
      <TimeShiftableTable className="mb-4" handleShowModal={handleShowModal} />





      <Modal as={Modal.Dialog} centered show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header>
          <Modal.Title className="h6">{modalTitle}</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleCloseModal} />
        </Modal.Header>
        <Modal.Body>
          {modalTitle === "+ Critical" ? (
            <FormsLoadCritical values={values_criticalLoads} setValues={setValues_criticalLoads} validated={validated} />
          ) : modalTitle === "+ Disconnectable" ? (
            <FormsLoadDisconnectable values={values_disconnectableLoads} setValues={setValues_disconnectableLoads} validated={validated_disconnectable} />
          ) : modalTitle === "+ Reducible" ? (
            <FormsLoadReducible values={values_reducible} setValues={setValues_reducible} validated={validated_reducible} />
          ) : modalTitle === "+ Power Shiftable" ? (
            <FormsPowerShiftable values={values_powershiftable} setValues={setValues_powershiftable} validated={validated_powershiftable} />
          ) : 
          (
            <FormsLoadTimeShiftable values={values_timeShiftable} setValues={setValues_timeShiftable} validated={validated_timeShiftable} />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleSave}>
            Save
          </Button>
          <Button variant="link" className="text-gray ms-auto" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};