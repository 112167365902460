import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";

// core styles
import "./scss/volt.scss";

// vendor styles
import "react-datetime/css/react-datetime.css";

import HomePage from "./pages/HomePage";
import ScrollToTop from "./components/ScrollToTop";

// Import the context providers
import { PVProvider } from "./context/PVContext";
import { BESSProvider } from "./context/BESSContext";
import { GENERATORProvider } from "./context/GENERATORContext";
import { ElectricalLoadProvider } from "./context/ElectricalLoadContext";
import { GlobalStateProvider } from './context/GlobalStateContext';
import { TimeSeriesProvider } from './context/TimeSeriesContext'; 
import { EVProvider } from './context/EVContext';
import { CriticalLoadProvider } from "./context/Loads_CriticalContext";
import { DisconnectableLoadProvider } from "./context/Loads_DisconnectableContext";
import { ReducibleLoadProvider } from "./context/Loads_ReducibleContext";
import {PowerShiftableLoadProvider} from "./context/Loads_PowerShiftableContext";
import { TimeShiftableLoadProvider } from "./context/Loads_TimeShiftableContext";
import { FlexibleRequestsProvider } from "./context/Requests_FlexibilityContext";
import { CasesProvider} from "./context/CasesContext";

const AppProviders = ({ children }) => (
  <GlobalStateProvider>
    <CasesProvider>
    <TimeSeriesProvider>
      <EVProvider>
        <FlexibleRequestsProvider>
        <CriticalLoadProvider>
          <PowerShiftableLoadProvider>
            <TimeShiftableLoadProvider>
              <ReducibleLoadProvider>
                <DisconnectableLoadProvider>
                  
                  <PVProvider>
                    <BESSProvider>
                      <GENERATORProvider>
                        <ElectricalLoadProvider>
                          {children}
          </ElectricalLoadProvider>
        </GENERATORProvider>
      </BESSProvider>
    </PVProvider>
  </DisconnectableLoadProvider>
</ReducibleLoadProvider>
</TimeShiftableLoadProvider>
</PowerShiftableLoadProvider>
    </CriticalLoadProvider>
    </FlexibleRequestsProvider>
    </EVProvider>
    </TimeSeriesProvider>
    </CasesProvider>
  </GlobalStateProvider>
);

ReactDOM.render(
  <HashRouter>
    <ScrollToTop />
    <AppProviders>
      <HomePage />
    </AppProviders>
  </HashRouter>,
  document.getElementById("root")
);