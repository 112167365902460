import React, { useState, useEffect } from "react";
import { Col, Row, Card, Form, Button } from '@themesberg/react-bootstrap';

export const EmissionsCostForm = ({ setEmissionsData }) => {
  const [values, setValues] = useState({
    costCO: '',
    costHC: '',
    costParticles: '',
    costNOx: '',
    costCO2: '' // Added new field
  });

  useEffect(() => {
    const savedValues = JSON.parse(localStorage.getItem("emissionsData")) || {};
    setValues(savedValues);
  }, []);

  useEffect(() => {
    localStorage.setItem("emissionsData", JSON.stringify(values));
  }, [values]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    // Perform regex check to ensure the value is a valid number or empty
    const isValidNumber = value === '' || /^\s*(?=.*[0-9])\d*(?:[.,]\d*)?\s*$/.test(value);
    // Update state directly
    if (isValidNumber) {
      setValues((prevValues) => {
        const newValues = {
          ...prevValues,
          [name]: value,
        };

        // If address-related fields change, update the parent component
        if (["costCO", "costHC", "costParticles", "costNOx", "costCO2"].includes(name)) {
          setEmissionsData({
            costCO: newValues.costCO,
            costHC: newValues.costHC,
            costParticles: newValues.costParticles,
            costNOx: newValues.costNOx,
            costCO2: newValues.costCO2 // Added new field
          });
        }

        return newValues;
      });
    }
  };

  const handleBlur = (event) => {
    const { name, value, placeholder } = event.target;

    // Perform regex check to ensure the value is a valid number or empty
    const isValidNumber = value === '' || /^\s*(?=.*[0-9])\d*(?:[.,]\d*)?\s*$/.test(value);

    if (isValidNumber && value !== '') {
      setValues({
        ...values,
        [name]: `${value} ${placeholder}`
      });
    } else if (!isValidNumber) {
      setValues({
        ...values,
        [name]: ''
      });
    }
  };

  const handleFocus = (event) => {
    const { name, value } = event.target;
    
    // Remove any appended placeholder text
    const newValue = value.split(' ')[0];
    setValues({
      ...values,
      [name]: newValue
    });
  };

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Emissions Cost</h5>
        <Form>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Cost CO *</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="€/kg"
                  name="costCO"
                  value={values.costCO}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  isInvalid={values.costCO === 'Invalid'}
                />
                <Form.Control.Feedback type="invalid">Please enter a valid number.</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Cost HC *</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="€/kg"
                  name="costHC"
                  value={values.costHC}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  isInvalid={values.costHC === 'Invalid'}
                />
                <Form.Control.Feedback type="invalid">Please enter a valid number.</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Cost Particles *</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="€/kg"
                  name="costParticles"
                  value={values.costParticles}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  isInvalid={values.costParticles === 'Invalid'}
                />
                <Form.Control.Feedback type="invalid">Please enter a valid number.</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Cost NOx *</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="€/kg"
                  name="costNOx"
                  value={values.costNOx}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  isInvalid={values.costNOx === 'Invalid'}
                />
                <Form.Control.Feedback type="invalid">Please enter a valid number.</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Cost CO2 *</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="€/kg"
                  name="costCO2"
                  value={values.costCO2}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  isInvalid={values.costCO2 === 'Invalid'}
                />
                <Form.Control.Feedback type="invalid">Please enter a valid number.</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};