import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Button, Modal, Alert } from "@themesberg/react-bootstrap";
import axios from "axios"; // ADD
import { useHistory } from "react-router-dom"; // ADD
import { ThreeDots } from "react-loader-spinner"; // UPDATE

import { PVTable } from "../components/Tables-pv";
import { FlexibilityRequestsTable } from "../components/Tables-flexRequests";

import { GridSellingForm } from "../components/Forms-gridSelling";
import { GridBuyingForm } from "../components/Forms-gridBuying";
import { PowerInformationForm } from "../components/Forms-powerInformation";
import { GridInformationForm } from "../components/Forms-gridInformation"; // Import the Grid Information form

import { GlobalStateContext } from "../context/GlobalStateContext";
import { FormsFlexibilityRequests } from "../components/Forms-flexRequest";

import { usePV } from "../context/PVContext"; // Import the custom hook
import { useBESS } from "../context/BESSContext";
import { useGENERATOR } from "../context/GENERATORContext";
import { useFlexibleRequests } from "../context/Requests_FlexibilityContext";

export default () => {
  // Contexts
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false); // ADD
  const history = useHistory(); // ADD
  const { addPvSystem } = usePV();
  const { addBessSystem } = useBESS();
  const { addGeneratorSystem } = useGENERATOR();
  const { flexibleRequests, addFlexibleRequest, updateFlexibleRequest } = useFlexibleRequests(); // Include updateBessSystem

  const [validated_flexReq, setValidated_flexReq] = useState(false); // Add validation state
  const [validated_gridInformation, setValidated_gridInformation] = useState(false); // Add validation state for grid information
  const [validated_gridSelling, setValidated_gridSelling] = useState(false);
  const [validated_gridBuying, setValidated_gridBuying] = useState(false); // Add validation state for buying
  const [validated_powerInformation, setValidated_powerInformation] = useState(false); // Add validation state for power information

  const [modalTitle, setModalTitle] = useState("");
  const [showModal, setShowModal] = useState(false);
  
  const [values_flexReq, setValues_flexReq] = useState({
    name: '',
    days: [],
    months: [],
    scale: 'Daily',
    metric: '%',
    photoperiod: 'Day',
    price: '',
    power_variance: 'power_limited',
    measurement_ref: 'punto_conexion_red',
    K_r: '',
    P_r: '',
    request_type: '+'
  });

  const [values_gridSelling, setValues_gridSelling] = useState({
    priceOption: '',
    fixedPrice: '',
    period1: '',
    period2: '',
    period3: '',
    period4: '',
    period5: '',
    period6: '',
    annualPrice: '',
    profile: ''
  });

  const [values_gridBuying, setValues_gridBuying] = useState({
    priceOption: '',
    fixedPrice: '',
    period1: '',
    period2: '',
    period3: '',
    period4: '',
    period5: '',
    period6: '',
    annualPrice: '',
    profile: ''
  });

  const [values_powerInformation, setValues_powerInformation] = useState({
    tariff: '',
    meter: '',
    period1: '',
    period2: '',
    period3: '',
    period4: '',
    period5: '',
    period6: ''
  });

  const [values_gridInformation, setValues_gridInformation] = useState({
    gridConnection: '',
    alwaysConnected: '',
    countryTariff: '',
    injectionToGrid: ''
  });

  const [disabledPriceOption, setDisabledPriceOption] = useState(false);
  const [editIndex, setEditIndex] = useState(null); // Track the index of the item being edited

  const handleShowModal = (title, data) => {
    setModalTitle(title);
    if (data) {
      setEditIndex(data.index);
      if (title === "Flexibility Request") {
        setValues_flexReq(data);
      }
    }
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEditIndex(null);
    setValues_flexReq({
      name: '',
      days: [],
      months: [],
      scale: 'Daily',
      metric: '%',
      photoperiod: 'Day',
      price: '',
      power_variance: 'power_limited',
      measurement_ref: 'punto_conexion_red',
      K_r: '',
      P_r: '',
      request_type: '+'
    });
  };

  const handleSave = () => {
    if (modalTitle === "Flexibility Request") {
      handleSave_flexReq();
    } 
  };

  const handleRunClick = async () => {
    // Check if all required fields are filled
    const requiredFieldsFilled = 
      values_gridInformation.gridConnection !== "" &&
      values_gridInformation.alwaysConnected !== "" &&
      values_gridInformation.countryTariff !== "" &&
      values_gridInformation.injectionToGrid !== "" &&
      values_powerInformation.tariff !== "" &&
      values_powerInformation.meter !== "" &&
      (
        values_gridBuying.fixedPrice !== "" ||
        values_gridBuying.period1 !== "" ||
        values_gridBuying.annualPrice !== "" ||
        values_gridBuying.profile !== ""
      );
  
    if (!requiredFieldsFilled) {
      setErrorMessage("Please fill in all required fields.");
      setSuccessMessage("");
      return;
    }
  
    // Clear error message and show loading spinner
    setErrorMessage("");
    setLoading(true);
  
    // Save the updated values to the global state
    setGlobalState((prevState) => ({
      ...prevState,
      gridInformation: values_gridInformation,
      powerInformation: values_powerInformation,
      gridSelling: values_gridSelling,
      gridBuying: values_gridBuying,
    }));
  
    console.log("Saved Global State:", JSON.stringify(globalState, null, 2));
  
    // Prepare data to send to the backend
    const dataToSend = {
      ...globalState,
      gridInformation: values_gridInformation,
      powerInformation: values_powerInformation,
      gridSelling: values_gridSelling,
      gridBuying: values_gridBuying,
    };
  
    // Dynamically determine the API URL based on environment
    const API_URL = process.env.REACT_APP_API_URL || "http://127.0.0.1:5000";
  
    try {
      // Send the data to the backend
      const response = await axios.post(`${API_URL}/load_chamber_any_json`, dataToSend, {
        timeout: 5000, // 5 seconds timeout
      });
  
      if (response.status === 200) {
        console.log("Data successfully sent to the server.");
        setSuccessMessage("Data successfully saved.");
        setTimeout(() => {
          setSuccessMessage("");
          setLoading(false);
          history.push("/constraints"); // Navigate to the next page
        }, 3000);
      } else {
        console.warn("Unexpected server response:", response.status);
        setErrorMessage("Server not responsive");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error during server request:", error.message || error);
      setErrorMessage("Server not responsive");
      setLoading(false);
    }
  
    // Clear the error message after 3 seconds
    setTimeout(() => {
      setErrorMessage("");
    }, 3000);
  };
  

  useEffect(() => {
    if (values_gridInformation.injectionToGrid === 'Never' || values_gridInformation.injectionToGrid === '') {
      setValues_gridSelling({
        ...values_gridSelling,
        priceOption: ''
      });
      setDisabledPriceOption(true);
    } else {
      setDisabledPriceOption(false);
    }
  }, [values_gridInformation.injectionToGrid]);

  const handleSave_flexReq = () => {
    const form = document.querySelector("#flexibilityRequestsForm");
    if (form.checkValidity() === false) {
      setValidated_flexReq(true);
    } else {
      if (editIndex !== null) {
        updateFlexibleRequest(editIndex, values_flexReq); // Update the existing flexibility request
      } else {
        addFlexibleRequest(values_flexReq); // Add a new flexibility request
      }
      console.log("Flexibility request saved: ", values_flexReq);
      setValues_flexReq({
        name: '',
        days: [],
        months: [],
        scale: 'Daily',
        metric: '%',
        photoperiod: 'Day',
        price: '',
        power_variance: 'power_limited',
        measurement_ref: 'punto_conexion_red',
        K_r: '',
        P_r: '',
        request_type: '+'
      });
      setShowModal(false);
      setValidated_flexReq(false);
      setEditIndex(null);
    }
  };

  const handleSave_gridSelling = () => {
    const form = document.querySelector("#gridSellingForm");
    if (form.checkValidity() === false) {
      setValidated_gridSelling(true);
    } else {
      // Add the new Grid Selling system to the context or state
      console.log("New Grid Selling system added: ", values_gridSelling);
      setValues_gridSelling({
        priceOption: '',
        fixedPrice: '',
        period1: '',
        period2: '',
        period3: '',
        period4: '',
        period5: '',
        period6: '',
        annualPrice: ''
      });
      setValidated_gridSelling(false);
    }
  };

  const handleSave_gridBuying = () => {
    const form = document.querySelector("#gridBuyingForm");
    if (form.checkValidity() === false) {
      setValidated_gridBuying(true);
    } else {
      // Add the new Grid Buying system to the context or state
      console.log("New Grid Buying system added: ", values_gridBuying);
      setValues_gridBuying({
        priceOption: '',
        fixedPrice: '',
        period1: '',
        period2: '',
        period3: '',
        period4: '',
        period5: '',
        period6: '',
        annualPrice: ''
      });
      setValidated_gridBuying(false);
    }
  };

  const handleSave_powerInformation = () => {
    const form = document.querySelector("#powerInformationForm");
    if (form.checkValidity() === false) {
      setValidated_powerInformation(true);
    } else {
      // Add the new Power Information system to the context or state
      console.log("New Power Information system added: ", values_powerInformation);
      setValues_powerInformation({
        tariff: '',
        meter: '',
        period1: '',
        period2: '',
        period3: '',
        period4: '',
        period5: '',
        period6: ''
      });
      setValidated_powerInformation(false);
    }
  };

  return (
    <>
      {loading && ( 
        <div className="loading-overlay"> 
          <ThreeDots color="#00BFFF" height={80} width={80} /> 
        </div> 
      )}
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4"></div>
      <Row className="align-items-center mt-4 mb-4">
        <Col>
          <h4>Grid Connection</h4>
        </Col>
        <Col className="text-right">
          <Button
            variant="success"
            type="button"
            className="me-2"
            onClick={handleRunClick}
            disabled={loading} // ADD
          >
            <FontAwesomeIcon icon={faArrowRight} className="me-2" />
            <span>Next</span>
          </Button>
        </Col>
      </Row>
      {errorMessage && (
        <Row>
          <Col>
            <Alert variant="danger">{errorMessage}</Alert>
          </Col>
        </Row>
      )}
      {successMessage && (
        <Row>
          <Col>
            <Alert variant="success">{successMessage}</Alert>
          </Col>
        </Row>
      )}
      <Row className="align-items-start  mt-4 mb-4">
        <Col>
          <GridInformationForm values={values_gridInformation} setValues={setValues_gridInformation} validated={validated_gridInformation} />
        </Col>
        <Col>
          <PowerInformationForm values={values_powerInformation} setValues={setValues_powerInformation} validated={validated_powerInformation} />
        </Col>
      </Row>

      <Row className="align-items-start  mt-4 mb-4">
        <Col>
          <GridSellingForm values={values_gridSelling} setValues={setValues_gridSelling} validated={validated_gridSelling} disabledPriceOption={disabledPriceOption} />
        </Col>
        <Col>
          <GridBuyingForm values={values_gridBuying} setValues={setValues_gridBuying} validated={validated_gridBuying} />
        </Col>
      </Row>

      <Row className="align-items-center mt-4 mb-4">
        <Col>
          <h4>Flexibility Requests</h4>
        </Col>
        <Col xs="auto">
          <Button
            variant="secondary"
            type="button"
            className="text-dark me-2"
            onClick={() => handleShowModal("Flexibility Request")}
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            <span>New</span>
          </Button>
        </Col>
      </Row>
      <FlexibilityRequestsTable className="mb-4" handleShowModal={handleShowModal} />


      <Modal as={Modal.Dialog} centered show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header>
          <Modal.Title className="h6">{modalTitle}</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleCloseModal} />
        </Modal.Header>
        <Modal.Body>
            <FormsFlexibilityRequests values={values_flexReq} setValues={setValues_flexReq} validated={validated_flexReq} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleSave}>
            Save
          </Button>
          <Button variant="link" className="text-gray ms-auto" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};