// context/PVContext.js
import React, { createContext, useState, useContext } from "react";

// Create a context with default value
const ELECTRICALLOADContext = createContext([]);

// Provider component
export const ElectricalLoadProvider = ({ children }) => {
  const [eletricalLoads, setElectricaLoad] = useState([]);

  const addElectricalLoad = (electricalLoad) => {
    setElectricaLoad([...eletricalLoads, electricalLoad]);
  };

  return (
    <ELECTRICALLOADContext.Provider value={{ eletricalLoads, addElectricalLoad }}>
      {children}
    </ELECTRICALLOADContext.Provider>
  );
};

// Custom hook to use the electrical load context
export const useElectricalLoad = () => {
  return useContext(ELECTRICALLOADContext);
};
