export const bessModels = [
  "BESS h2glass",
  "General BESS",
  "Generic 1kWh Li-Ion",
  "Generic 1kWh Lead Acid",
  "Generic Vanadium",
  "Schneider BESS 125 kW Li-Ion",
  "Schneider BESS 250 kW Li-Ion",
  "Schneider BESS 500 kW Li-Ion",
  "Schneider BESS 1000 kW Li-Ion",
  "Discover 12VRE-3000TF-L",
  "Discover 2VRE3-500TG",
  "Discover 2VRE-4000TF-U",
  "Discover 2VRE-9000TF-U",
  "Discover 2VRE4-1500TF",
  "Discover 2VRE-6200TF-U",
  "Discover 2VRE-7500TF-U",
  "Discover 2VRE-8900TG",
  "Discover 6VRE-2400TF",
  "EnerDel Secure+ [101kWh]",
  "Enphase 1.2kWh 0.27kW",
  "EnerStore 50 Agile Flow Battery",
  "CELLCUBEâ FB 10-40",
  "CELLCUBEâ FB 10-70",
  "CELLCUBEâ FB 10-100",
  "CELLCUBEâ FB 10-130",
  "CELLCUBEâ FB 20-40",
  "CELLCUBEâ FB 20-70",
  "CELLCUBEâ FB 20-100",
  "CELLCUBEâ FB 20-130",
  "CELLCUBEâ FB 30-40",
  "CELLCUBEâ FB 30-70",
  "CELLCUBEâ FB 30-100",
  "CELLCUBEâ FB 30-130",
  "CELLCUBEâ FB 200-400",
  "CELLCUBEâ FB 200-800",
  "CELLCUBEâ FB 200-1600",
  "CELLCUBEâ FB 250-1000",
  "CELLCUBEâ FB 250-2000",
  "280kW-1MWh Primus Power EnergyPod",
  "20kW-72kWh Primus Power EnergyCell",
  "Redflow LSB",
  "Redflow ZBM2",
  "Redflow ZCell",
  "Saft Intensium Max+20M ESSU",
  "Sunverge SIS XW+6848",
  "Tesla Powerpack 2",
  "Tesla Powerwall 2.0",
  "Trojan IND9-6V",
  "Trojan IND13-6V",
  "Trojan IND17-6V",
  "Trojan IND23-4V",
  "Trojan IND27-2V",
  "Trojan IND29-4V",
  "Trojan IND33-2V",
  "Trojan T-105",
  "Trojan L16P",
  "UET Uni.System 500 kW 8 hr",
  "UET Uni.System 500 kW 6 hr",
  "UET Uni.System 500 kW 4 hr",
  "UET ReFlex 100 kW 5 hr",
  "Schneider BESS 62,5 kW Li-Ion",
  "Schneider BESS 375 kW Li-Ion"
];