import React from 'react';
import { Card, Table, Dropdown, ButtonGroup, Button } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons';
import { useTimeShiftableLoad } from '../context/Loads_TimeShiftableContext'; // Import the custom hook

export const TimeShiftableTable = ({ handleShowModal }) => { // Pass handleShowModal as a prop
  const { timeShiftableLoads, deleteTimeShiftableLoad } = useTimeShiftableLoad(); // Get time shiftable loads and delete function from the context

  const TableRow = (props) => {
    const { index, name, days, months, scale, metric, photoperiod, flexibility_cost, is_flex_request, duration } = props;

    const handleRemove = () => {
      deleteTimeShiftableLoad(index); // Call the delete function with the index
    };

    const handleEdit = () => {
      handleShowModal("+ Time Shiftable", { index, ...props }); // Pass the index and data to the modal
    };

    return (
      <tr style={{ height: '50px' }}>
        <td className="border-0">
          <Dropdown as={ButtonGroup} alignRight>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="h6">{name || ''}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={handleEdit}>
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
              </Dropdown.Item>
              <Dropdown.Item className="text-danger" onClick={handleRemove}>
                <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
        <td className="border-0">{days.join(', ') || ''}</td>
        <td className="border-0">{months.join(', ') || ''}</td>
        <td className="border-0">{scale || ''}</td>
        <td className="border-0">{metric || ''}</td>
        <td className="border-0">{photoperiod || ''}</td>
        <td className="border-0">{flexibility_cost || ''}</td>
        <td className="border-0">{is_flex_request ? 'Yes' : 'No'}</td>
        <td className="border-0">{duration || ''}</td>
      </tr>
    );
  };

  const rowsToFill = 6;
  const currentRows = timeShiftableLoads.length;
  const placeholdersNeeded = rowsToFill - currentRows > 0 ? rowsToFill - currentRows : 0;

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body className="pb-0">
        <Table responsive className="table-centered table-nowrap rounded mb-0">
          <thead className="thead-light">
            <tr>
              <th className="border-0">Name</th>
              <th className="border-0">Days</th>
              <th className="border-0">Months</th>
              <th className="border-0">Scale</th>
              <th className="border-0">Metric</th>
              <th className="border-0">Photoperiod</th>
              <th className="border-0">Flexibility Cost</th>
              <th className="border-0">Flex Request</th>
              <th className="border-0">Duration</th>
            </tr>
          </thead>
          <tbody>
            {timeShiftableLoads.length > 0 ? (
              <>
                {timeShiftableLoads.map((timeShiftableLoad, index) => (
                  <TableRow key={index} index={index} {...timeShiftableLoad} />
                ))}
                {Array.from({ length: placeholdersNeeded }).map((_, idx) => (
                  <tr key={`placeholder-${idx}`} style={{ height: '50px' }}>
                    <td className="border-0" colSpan="9"></td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td className="text-center" colSpan="9" style={{ height: '300px' }}>
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};