import React from "react";
import { Col, Row, Card, Form } from "@themesberg/react-bootstrap";
import { bessModels } from "../data/models-bess";

export const BESSForm = ({ values, setValues, validated }) => {
  const handleChange = (event) => {
    const { name, value } = event.target;

    // Perform regex check to ensure the value is a valid number or empty
    const isValidNumber = value === '' || /^\s*(?=.*[0-9])\d*(?:[.,]\d*)?\s*$/.test(value);

    // Update state only if the value is valid or the field is not the combobox
    if (isValidNumber || name === "newBessCanBeAdded" || name === "model" || name === "name" || name === "forecast") {
      setValues({
        ...values,
        [name]: value
      });
    }
  };

  const handleBlur = (event) => {
    const { name, value, placeholder} = event.target;

    // Perform regex check to ensure the value is a valid number or empty
    const isValidNumber = value === '' || /^\s*(?=.*[0-9])\d*(?:[.,]\d*)?\s*$/.test(value);

    if (isValidNumber && value !== '') {
      setValues({
        ...values,
        [name]: `${value} ${placeholder}`
      });
    } else if (!isValidNumber && (name === "newBessCanBeAdded" || name === "model" || name === "name" || name === "forecast")) {
      setValues({
        ...values,
        [name]: value
      });
    } else if (!isValidNumber) {
      setValues({
        ...values,
        [name]: ''
      });
    }
  };

  const handleFocus = (event) => {
    const { name, value } = event.target;
    
    // Remove any appended placeholder text
    const newValue = value.split(' ')[0];
    setValues({
      ...values,
      [name]: newValue
    });
  };




  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <Form id="bessForm" noValidate validated={validated}>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Name *</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="BESS System"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">
                  Name is required
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Number of BESS already in the system *</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="uds."
                  name="bessInstalledCapacity"
                  value={values.bessInstalledCapacity}
                  onChange={handleChange}
                  onBlur= {handleBlur}
                  onFocus= {handleFocus}
                />
                <Form.Control.Feedback type="invalid">
                  BESS Installed Capacity is required
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Model *</Form.Label>
                <Form.Select
                  required
                  name="model"
                  value={values.model}
                  onChange={handleChange}
                >
                  <option value="">No BESS Selected</option>
                  {bessModels.map((model, index) => (
                    <option key={index} value={model}>{model}</option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Model is required
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>BESS Install Capacity to Add (Assuming Fixed)</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="kWh"
                  name="bessInstallCapacityToAdd"
                  value={values.bessInstallCapacityToAdd}
                  onChange={handleChange}
                  onBlur= {handleBlur}
                  onFocus= {handleFocus}
                />
                <Form.Control.Feedback type="invalid">
                  BESS Install Capacity to Add is required
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>New batteries can be added? *</Form.Label>
                <Form.Select
                  required
                  name="newBessCanBeAdded"
                  value={values.newBessCanBeAdded}
                  onChange={handleChange}
                >
                  <option value="">Please Select</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  New batteries can be added? is required
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Fixed Incentive *</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="€/kWh"
                  name="fixedIncentive"
                  value={values.fixedIncentive}
                  onChange={handleChange}
                  onBlur= {handleBlur}
                  onFocus= {handleFocus}
                />
                <Form.Control.Feedback type="invalid">
                  Fixed Incentive is required
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};
