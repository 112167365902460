import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  max-height: 300px; /* Set maximum height for the container */
  overflow: hidden;
`;

const LineNumbers = styled.div`
  width: 80px; /* Increase the width of the line numbers column */
  padding: 10px;
  background: #f0f0f0;
  border-right: 1px solid #ddd;
  text-align: right;
  user-select: none;
  color: #888;
  overflow-y: auto; /* Enable vertical scrolling */
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden; /* Hide the scrollbar */
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  border: none;
  outline: none;
  resize: none;
  line-height: 1.5;
  font-family: monospace;
  overflow-y: auto; /* Enable vertical scrolling */
`;

const TextAreaWithLineNumbers = ({ value, onChange, onBlur, onFocus, placeholder, name }) => {
  const [lineNumbers, setLineNumbers] = useState([]);
  const textAreaRef = useRef(null);

  useEffect(() => {
    const lines = value.split('\n').length;
    setLineNumbers(Array.from({ length: lines }, (_, i) => i + 1));
  }, [value]);

  const handleScroll = () => {
    const textArea = textAreaRef.current;
    const lineNumbersDiv = textArea.previousSibling;
    lineNumbersDiv.scrollTop = textArea.scrollTop;
  };

  return (
    <Container>
      <LineNumbers>
        {lineNumbers.map((lineNumber) => (
          <div key={lineNumber}>{lineNumber}</div>
        ))}
      </LineNumbers>
      <TextArea
        ref={textAreaRef}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        placeholder={placeholder}
        name={name}
        rows={15} /* Set the number of visible rows */
        onScroll={handleScroll}
      />
    </Container>
  );
};

export default TextAreaWithLineNumbers;