import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faRocket } from "@fortawesome/free-solid-svg-icons";
import Progress from "../components/Progress";
import { Button, Row, Modal, Alert, Col } from "@themesberg/react-bootstrap";
import { CasesTable } from "../components/Tables-cases";
import { FormsCases } from "../components/Forms-cases";
import { useCases } from "../context/CasesContext";
import { useBESS } from "../context/BESSContext";
import { useElectricalLoad } from "../context/ElectricalLoadContext";
import { useEV } from "../context/EVContext";
import { useGENERATOR } from "../context/GENERATORContext";
import { GlobalStateContext } from "../context/GlobalStateContext";
import { useCriticalLoad } from "../context/Loads_CriticalContext";
import { useDisconnectableLoad } from "../context/Loads_DisconnectableContext";
import { usePowerShiftableLoad } from "../context/Loads_PowerShiftableContext";
import { useReducibleLoad } from "../context/Loads_ReducibleContext";
import { useTimeShiftableLoad } from "../context/Loads_TimeShiftableContext";
import { usePV } from "../context/PVContext";
import { useFlexibleRequests } from "../context/Requests_FlexibilityContext";
import { useTimeSeries } from "../context/TimeSeriesContext";
import axios from "axios"; // ADD
import { useHistory } from "react-router-dom"; // ADD
import { ThreeDots } from "react-loader-spinner"; // ADD

export default () => {
  const [progress, setProgress] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [isProgressVisible, setIsProgressVisible] = useState(false);
  const { cases, updateCase, addCase } = useCases(); // ADD
  const { bessSystems } = useBESS(); // ADD
  const { electricalLoads } = useElectricalLoad(); // ADD
  const { evSystems } = useEV(); // ADD
  const { generatorSystems } = useGENERATOR(); // ADD
  const { globalState, setGlobalState } = useContext(GlobalStateContext); // FIXED
  const { criticalLoads } = useCriticalLoad(); // ADD
  const { disconnectableLoads } = useDisconnectableLoad(); // ADD
  const { powerShiftableLoads } = usePowerShiftableLoad(); // ADD
  const { reducibleLoads } = useReducibleLoad(); // ADD
  const { timeShiftableLoads } = useTimeShiftableLoad(); // ADD
  const { pvSystems } = usePV(); // ADD
  const { flexibleRequests } = useFlexibleRequests(); // ADD
  const { timeSeries } = useTimeSeries(); // ADD
  const history = useHistory(); // ADD
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");

  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false); // ADD

  const [values_cases, setValues_cases] = useState({
    type: '',
    pv: false,
    bess: false,
    genset: false,
    flexibleLoadsSizing: false,
    flexibleRequestSizing: false,
    assetSize: '',
    flexibleLoadsOperation: false,
    flexibleRequestOperation: false,
    hiredPower: ''
  });

  const [editIndex, setEditIndex] = useState(null); // Track the index of the item being edited

  const handleShowModal = (title, data) => {
    setModalTitle(title);
    if (data) {
      setEditIndex(data.index);
      if (title === "Case") {
        setValues_cases(data);
      } 
    }
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEditIndex(null);
    setValues_cases({
      type: '',
      pv: false,
      bess: false,
      genset: false,
      flexibleLoadsSizing: false,
      flexibleRequestSizing: false,
      assetSize: '',
      flexibleLoadsOperation: false,
      flexibleRequestOperation: false,
      hiredPower: ''
    });
  };

  const handleSave = () => {
    if (modalTitle === "Case") {
      handleSave_case();
    } 
  };

  const handleSave_case = () => {
    const form = document.querySelector("#casesForm");
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      if (editIndex !== null) {
        updateCase(editIndex, values_cases); // Update case
      } else {
        addCase(values_cases); // Add new case
      }
      console.log("Case saved: ", values_cases);
      setValues_cases({
        type: '',
        pv: false,
        bess: false,
        genset: false,
        flexibleLoadsSizing: false,
        flexibleRequestSizing: false,
        assetSize: '',
        flexibleLoadsOperation: false,
        flexibleRequestOperation: false,
        hiredPower: ''
      });
      setShowModal(false);
      setValidated(false);
      setEditIndex(null);
    }
  };

  useEffect(() => {
    let timer;
    if (isRunning) {
      timer = setInterval(() => {
        setProgress(prevProgress => {
          if (prevProgress >= 100) {
            clearInterval(timer);
            setIsRunning(false); // Reset isRunning to false after reaching 100%
            return 100;
          }
          return prevProgress + 1;
        });
      }, 80); // Update progress every 80 milliseconds
    }

    return () => clearInterval(timer);
  }, [isRunning]);

  const handleRunClick = async () => {
    // Ensure there is at least one case
    if (cases.length === 0) {
      setErrorMessage("Please add at least one case.");
      setTimeout(() => setErrorMessage(""), 3000); // Clear error message after 3 seconds
      return; // Exit early if no cases
    }
  
    setLoading(true);
    
    // Gather all the information from the contexts
    const dataToSend = {
      cases,
      bessSystems,
      electricalLoads,
      evSystems,
      generatorSystems,
      globalState,
      criticalLoads,
      disconnectableLoads,
      powerShiftableLoads,
      reducibleLoads,
      timeShiftableLoads,
      pvSystems,
      flexibleRequests,
      timeSeries,
    };
  
    console.log("Data being sent to the server:", JSON.stringify(dataToSend, null, 2));
  
    // Dynamically determine the API URL based on environment
    const API_URL = process.env.REACT_APP_API_URL || "http://127.0.0.1:5000";
  
    try {
      // Send the data to the backend
      const response = await axios.post(`${API_URL}/send_request`, dataToSend, {
        timeout: 5000, // 5 seconds timeout
      });
  
      if (response.status === 200) {
        console.log("Data successfully sent to the server.");
        console.log("Response data: ", response.data.request_id);
  
        // Save 'request_id' from the response to the global state
        setGlobalState((prevState) => ({
          ...prevState,
          request_id: response.data.request_id,
        }));
  
        // Trigger animation for successful data send
        setProgress(1);
        setIsRunning(true);
        setIsProgressVisible(true);
  
        setSuccessMessage("Request successfully processed.");
  
        setTimeout(() => {
          setSuccessMessage("");
          history.push("/completed"); // Navigate to the next page
          setLoading(false);
        }, 11000);
      } else {
        console.warn("Unexpected server response:", response.status);
        setErrorMessage("Server not responsive");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error during server request:", error.message || error);
      setErrorMessage("Server not responsive");
      setLoading(false);
    }
  
    // Clear the error message after 3 seconds
    setTimeout(() => {
      setErrorMessage("");
    }, 3000);
  };
  

  return (
    <>
      {loading && ( 
        <div className="loading-overlay"> 
          <ThreeDots color="#00BFFF" height={80} width={80} /> 
        </div> 
      )}

      <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-xl-0">
          <h4>Cases to Run</h4>
        </div>
      </div>
      {errorMessage && (
        <Row>
          <Col>
            <Alert variant="danger">{errorMessage}</Alert>
          </Col>
        </Row>
      )}
      {successMessage && (
        <Row>
          <Col>
            <Alert variant="success">{successMessage}</Alert>
          </Col>
        </Row>
      )}
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <Button
          variant="primary"
          type="button"
          className="me-2"
          onClick={() => handleShowModal("Case")}
        >
          <FontAwesomeIcon icon={faPlus} className="me-2" />
          <span>New</span>
        </Button>

        <div className="d-flex">
          <Button
            variant="success"
            type="button"
            className="me-2"
            onClick={handleRunClick}
          >
            <FontAwesomeIcon icon={faRocket} className="me-2" />
            <span>Run</span>
          </Button>
        </div>
      </div>
      <CasesTable handleShowModal={handleShowModal} />
      {isProgressVisible && (
        <Row className="d-flex flex-wrap flex-md-nowrap align-items-center py-4">
          <Progress
            variant="success"
            label={progress < 100 ? "Sending..." : "Completed"}
            value={progress}
          />
        </Row>
      )}

      <Modal as={Modal.Dialog} centered show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header>
          <Modal.Title className="h6">{modalTitle}</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleCloseModal} />
        </Modal.Header>
        <Modal.Body>
          <FormsCases values={values_cases} setValues={setValues_cases} validated={validated} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleSave}>
            Save
          </Button>
          <Button variant="link" className="text-gray ms-auto" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};