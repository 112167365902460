import React, { createContext, useState, useContext } from 'react';

const EVContext = createContext();

export const EVProvider = ({ children }) => {
  const [evData, setEvData] = useState({
    EV: '',
    modelEV: '',
    smartCharging: '',
    numberOfVehicles: '',
    numberOfChargingPoints: '',
    maxChargingPowerEVBattery: '',
    maxPowerChargingPoint: '',
    maxPowerChargingStation: '',
    probDistArrivals: '',
    param1Arrivals: '',
    param2Arrivals: '',
    days: '',
    probDistParked: '',
    param1Parked: '',
    param2Parked: '',
    timeSeries: ''
  });

  const updateEVData = (key, value) => {
    setEvData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  return (
    <EVContext.Provider value={{ evData, updateEVData }}>
      {children}
    </EVContext.Provider>
  );
};

export const useEV = () => {
  return useContext(EVContext);
};