import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Card, Container, Button } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { GlobalStateContext } from "../context/GlobalStateContext";
import { Routes } from "../routes";
import BannerImage from "../assets/img/eRoots_Google_Workspace-01.png";

export default () => {
  const { globalState } = useContext(GlobalStateContext); // Use useContext to access globalState

  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          {/* Add Banner Image */}
          <Row className="justify-content-center mb-4">
            <Col xs={12} className="d-flex justify-content-center">
              <img src={BannerImage} alt="Banner" className="img-fluid" style={{ maxHeight: '30px' }} />
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Welcome!</h3>
                  <p className="mt-2">This is the Microgrid Sizing and Optimization Tool. This tool will take you through step-by-step to understand your case.</p>
                </div>
                <div className="text-center">
                  <Button as={Link} to="/customer" variant="primary">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" /> Click here to begin!
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};