import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faAlignLeft, 
  faCar, 
  faThermometerHalf, 
  faTint,
  faArrowRight
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Button,
  Dropdown,
  Modal,
  Form,
  Alert
} from "@themesberg/react-bootstrap";

import {
  ProfileWidget,
} from "../../components/Widgets-profileWidget"; // Import the new widget 

import ProfileBaselineForm from "../../components/Forms-profile-baseline"; // Import the new form
import EVForm from "../../components/Forms-ev"; // Import the EV form
import { useTimeSeries } from "../../context/TimeSeriesContext"; // Import the custom hook
import { useEV } from "../../context/EVContext"; // Import the EV context
import axios from "axios"; // ADD
import { useHistory } from "react-router-dom"; // ADD
import { ThreeDots } from "react-loader-spinner"; // ADD

export default () => {
  // Contexts
  const API_URL = process.env.REACT_APP_API_URL;
  const { timeSeriesData, updateBaseline } = useTimeSeries(); // Use the time series context
  const { evData, updateEVData } = useEV(); // Use the EV context
  const history = useHistory(); // ADD
  const [loading, setLoading] = useState(false); // ADD
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState(""); // ADD
  const [successMessage, setSuccessMessage] = useState(""); // ADD
  const [values, setValues] = useState({
    baseline: timeSeriesData.baseline, // Initialize with context data
    EV: evData.EV,
    modelEV: evData.modelEV,
    smartCharging: evData.smartCharging,
    numberOfVehicles: evData.numberOfVehicles,
    numberOfChargingPoints: evData.numberOfChargingPoints,
    maxChargingPowerEVBattery: evData.maxChargingPowerEVBattery,
    maxPowerChargingPoint: evData.maxPowerChargingPoint,
    maxPowerChargingStation: evData.maxPowerChargingStation,
    flexibilityCost: evData.flexibilityCost,
    vehiclesArrivals: evData.vehiclesArrivals,
    probDistArrivals: evData.probDistArrivals,
    param1Arrivals: evData.param1Arrivals,
    param2Arrivals: evData.param2Arrivals,
    days: evData.days,
    parkedTime: evData.parkedTime,
    probDistParked: evData.probDistParked,
    param1Parked: evData.param1Parked,
    param2Parked: evData.param2Parked,
    timeSeries: evData.timeSeries,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleBlur = () => {
    // Do nothing
  };

  const handleFocus = () => {
    // Do nothing
  };

  const getModalContent = (title) => {
    if (title === "Baseline") {
      return (
        <ProfileBaselineForm
          values={values}
          handleChange={handleChange}
          handleBlur={handleBlur}
          handleFocus={handleFocus}
        />
      );
    } else if (title === "Electric Vehicle") {
      return (
        <EVForm
          values={values}
          handleChange={handleChange}
          handleBlur={handleBlur}
          handleFocus={handleFocus}
        />
      );
    }
    return null;
  };

  const handleShowModal = (title) => {
    setModalTitle(title);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSave = () => {
    if (modalTitle === "Baseline") {
      if (values.baseline.trim() === "") {
        setErrorMessage("Baseline cannot be empty.");
        setTimeout(() => setErrorMessage(""), 3000); // Clear error message after 3 seconds
        return;
      }
      updateBaseline(values.baseline);
    } else if (modalTitle === "Electric Vehicle") {
      // Handle saving EV profile data
      Object.keys(values).forEach(key => {
        updateEVData(key, values[key]);
      });
      setSuccessMessage("EV Profile saved successfully.");
      setTimeout(() => setSuccessMessage(""), 3000); // Clear success message after 3 seconds
    }
    setShowModal(false);
  };

  const handleRunClick = async () => {
    if (values.baseline.trim() === "") {
      setErrorMessage("Baseline cannot be empty.");
      setTimeout(() => setErrorMessage(""), 3000);
      return;
    }
  
    const data = {
      TimeSeriesData: timeSeriesData,
      EVData: evData,
    };
  
    setLoading(true);
  
    try {
      const response = await axios.post(`${API_URL}/load_chamber_any_json`, data, {
        timeout: 5000,
      });
  
      if (response.status === 200) {
        setSuccessMessage("Data successfully sent to the server.");
        setTimeout(() => {
          setSuccessMessage("");
          setLoading(false);
          history.push("/microgrid/flexible-loads");
        }, 3000);
      } else {
        setErrorMessage("Server not responsive");
        setLoading(false);
        setTimeout(() => setErrorMessage(""), 3000);
      }
    } catch (error) {
      setErrorMessage("Server not responsive");
      setLoading(false);
      setTimeout(() => setErrorMessage(""), 3000);
    }
  };

  return (
    <>
      {loading && ( 
        <div className="loading-overlay"> 
          <ThreeDots color="#00BFFF" height={80} width={80} /> 
        </div> 
      )}
      <Row className="align-items-center mt-4 mb-4">
        <Col>
          <h4>Loads</h4>
        </Col>
        <Col className="text-right">
          <Button
            variant="success"
            type="button"
            className="me-2"
            onClick={handleRunClick}
            disabled={loading} // Disable button while loading
          >
            <FontAwesomeIcon icon={faArrowRight} className="me-2" />
            <span>Next</span>
          </Button>
        </Col>
      </Row>

      {errorMessage && (
        <Row>
          <Col>
            <Alert variant="danger">{errorMessage}</Alert>
          </Col>
        </Row>
      )}
      {successMessage && (
        <Row>
          <Col>
            <Alert variant="success">{successMessage}</Alert>
          </Col>
        </Row>
      )}

      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <Dropdown className="btn-toolbar">
          <Dropdown.Toggle
            as={Button}
            variant="primary"
            size="sm"
            className="me-2"
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            New Profile
          </Dropdown.Toggle>
          <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-2">
              <Dropdown.Item className="fw-bold" onClick={() => handleShowModal("Baseline")}>
                <FontAwesomeIcon icon={faAlignLeft} className="me-2" /> Baseline
              </Dropdown.Item>
              <Dropdown.Item className="fw-bold" onClick={() => handleShowModal("Electric Vehicle")}>
                <FontAwesomeIcon icon={faCar} className="me-2" /> Electric Vehicle
              </Dropdown.Item>
              <Dropdown.Item className="fw-bold">
                <FontAwesomeIcon icon={faThermometerHalf} className="me-2" /> Thermal (Future Update)
              </Dropdown.Item>
              <Dropdown.Item className="fw-bold">
                <FontAwesomeIcon icon={faTint} className="me-2" /> Hydrogen (Future Update)
              </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
      </div>

      <Row className="justify-content-md-center">
        <Col xs={12} className="mb-10 d-none d-sm-block">
          <ProfileWidget title="Profiles" value=" " percentage={10.57}  className="mb-4" />
        </Col>
      </Row>

      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4"></div>

      <Modal as={Modal.Dialog} centered show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header>
          <Modal.Title className="h6">{modalTitle}</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleCloseModal} />
        </Modal.Header>
        <Modal.Body>
          {getModalContent(modalTitle)}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleSave}>
            Save
          </Button>
          <Button variant="link" className="text-gray ms-auto" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};