import React, { useState } from 'react';
import { Row, Col, Form, Card } from '@themesberg/react-bootstrap';
import styled from 'styled-components';
import { Transition } from 'react-transition-group';
import TextAreaWithLineNumbers from './TextAreaWithLineNumbers'; // Adjust the import path as necessary

const FadeDiv = styled.div`
  transition: 0.5s;
  opacity: ${({ state }) => (state === "entered" ? 1 : 0)};
  display: ${({ state }) => (state === "exited" ? "none" : "block")};
`;

const FadeTransition = ({ children, ...rest }) => (
  <Transition {...rest}>
    {state => <FadeDiv state={state}>{children}</FadeDiv>}
  </Transition>
);

export const FormsLoadCritical = ({ values, setValues }) => {
  const [scale, setScale] = useState(values.scale || 'Daily');

  const handleScaleChange = (event) => {
    const { value } = event.target;
    setScale(value);
    handleChange(event);

    // Clear days and months when switching to Yearly
    if (value === 'Yearly') {
      setValues((prevValues) => ({
        ...prevValues,
        days: [],
        months: []
      }));
    }
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    if (type === 'checkbox') {
      setValues((prevValues) => ({
        ...prevValues,
        [name]: checked
          ? [...prevValues[name], value]
          : prevValues[name].filter((v) => v !== value),
      }));
    } else {
      setValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  const handleBlur = (event) => {
    const { name, value, placeholder } = event.target;

    if (value !== '') {
      setValues((prevValues) => ({
        ...prevValues,
        [name]: `${value} ${placeholder}`
      }));
    } else {
      setValues((prevValues) => ({
        ...prevValues,
        [name]: ''
      }));
    }
  };

  const handleFocus = (event) => {
    const { name, value } = event.target;

    const newValue = value.split(' ')[0];
    setValues((prevValues) => ({
      ...prevValues,
      [name]: newValue
    }));
  };

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <Form id="loadCriticalForm" noValidate>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  value={values.name}
                  onChange={handleChange}
                  name="name"
                  placeholder="Enter name"
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Scale</Form.Label>
                <Form.Select
                  value={scale}
                  onChange={handleScaleChange}
                  name="scale"
                >
                  <option value="Daily">Daily</option>
                  <option value="Yearly">Yearly</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <FadeTransition in={scale === 'Daily'}>
            <div>
              <Row>
                <Col md={12} className="mb-3">
                  <Form.Group>
                    <Form.Label>Days</Form.Label>
                    <div>
                      {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day, index) => (
                        <Form.Check
                          inline
                          label={day}
                          type="checkbox"
                          name="days"
                          value={day}
                          checked={values.days.includes(day)}
                          onChange={handleChange}
                          key={index}
                        />
                      ))}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={12} className="mb-3">
                  <Form.Group>
                    <Form.Label>Months</Form.Label>
                    <div>
                      {['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].map((month, index) => (
                        <Form.Check
                          inline
                          label={month}
                          type="checkbox"
                          name="months"
                          value={month}
                          checked={values.months.includes(month)}
                          onChange={handleChange}
                          key={index}
                        />
                      ))}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </FadeTransition>
          <FadeTransition in={scale === 'Yearly'}>
            <div>
              {/* Yearly specific fields can be added here if needed */}
            </div>
          </FadeTransition>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Metric</Form.Label>
                <Form.Select
                  value={values.metric}
                  onChange={handleChange}
                  name="metric"
                >
                  <option value="%">%</option>
                  <option value="kW">kW</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Photoperiod</Form.Label>
                <Form.Select
                  value={values.photoperiod}
                  onChange={handleChange}
                  name="photoperiod"
                >
                  <option value="Day">Day</option>
                  <option value="Night">Night</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Supply Source</Form.Label>
                <Form.Select
                  value={values.supply_source}
                  onChange={handleChange}
                  name="supply_source"
                >
                  <option value="PV">PV</option>
                  <option value="BESS">BESS</option>
                  <option value="GENSET">GENSET</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Islandable</Form.Label>
                <Form.Select
                  value={values.islandable}
                  onChange={handleChange}
                  name="islandable"
                >
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mb-3">
              <Form.Group>
                <Form.Label>Demand</Form.Label>
                <TextAreaWithLineNumbers
                  value={values.demand || ''}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  placeholder="Enter demand data"
                  name="demand"
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};