import React from "react";
import { Col, Row, Card, Form } from "@themesberg/react-bootstrap";
import { SwitchTransition, Transition } from "react-transition-group";
import styled from "styled-components";

const FadeDiv = styled.div`
  transition: 0.5s;
  opacity: ${({ state }) => (state === "entered" ? 1 : 0)};
  display: ${({ state }) => (state === "exited" ? "none" : "block")};
`;

const FadeTransition = ({ children, ...rest }) => (
  <Transition {...rest}>
    {state => <FadeDiv state={state}>{children}</FadeDiv>}
  </Transition>
);

export const PowerInformationForm = ({ values, setValues, validated }) => {
  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value
    });
  };

  const handleBlur = (event) => {
    const { name, value, placeholder } = event.target;
    setValues({
      ...values,
      [name]: `${value} ${placeholder}`
    });
  };

  const handleFocus = (event) => {
    const { name, value } = event.target;
    const newValue = value.split(' ')[0];
    setValues({
      ...values,
      [name]: newValue
    });
  };

  const isTwoPeriodsTariff = values.tariff === "2.0 TD";
  const isTariffSelected = values.tariff !== "";

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Power Information</h5>
        <Form id="powerInformationForm" noValidate validated={validated}>
          <Form.Group className="mb-3">
            <Form.Label>Tariff *</Form.Label>
            <Form.Select
              required
              name="tariff"
              value={values.tariff}
              onChange={handleChange}
            >
              <option value="">Please Select</option>
              <option value="2.0 TD">2.0 TD</option>
              <option value="3.0 TD">3.0 TD</option>
              <option value="6.1 TD">6.1 TD</option>
              <option value="6.2 TD">6.2 TD</option>
              <option value="6.3 TD">6.3 TD</option>
              <option value="6.4 TD">6.4 TD</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Tariff is required
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Meter *</Form.Label>
            <Form.Select
              required
              name="meter"
              value={values.meter}
              onChange={handleChange}
            >
              <option value="">Please Select</option>
              <option value="Type 1">Type 1 (1 kV ≤ Voltage &lt; 30 kV)</option>
              <option value="Type 2">Type 2 (450 kW ≤ Voltage &lt; 10 MW)</option>
              <option value="Type 3">Type 3 (50 kW &lt; Voltage &lt; 450 kW)</option>
              <option value="Type 4">Type 4 (15 kW &lt; Voltage ≤ 50 kW)</option>
              <option value="Type 5">Type 5 (Voltage ≤ 15 kW)</option>
              {/* Tipo 1: potencia contratada ≥ 10 MW
                Tipo 2: 450 kW ≤ potencia contratada < 10 MW
                Tipo 3: 50 kW < potencia contratada < 450 kW
                Tipo 4: 15 kW < potencia contratada ≤ 50 kW
                Tipo 5: potencia contratada ≤ 15 kW */}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Meter is required
            </Form.Control.Feedback>
          </Form.Group>
          <SwitchTransition mode="out-in">
            <FadeTransition
              key={isTariffSelected ? (isTwoPeriodsTariff ? "two-periods" : "six-periods") : "no-periods"}
              timeout={500}
              unmountOnExit
              mountOnEnter
            >
              {isTariffSelected && (
                <>
                  <Form.Label>Current Hired Power *</Form.Label>
                  <Form.Group className="mb-3">
                    <Form.Label>Period 1</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="kW"
                      name="period1"
                      value={values.period1}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      onFocus={handleFocus}
                    />
                    <Form.Control.Feedback type="invalid">
                      Period 1 is required
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Period 2</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="kW"
                      name="period2"
                      value={values.period2}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      onFocus={handleFocus}
                    />
                    <Form.Control.Feedback type="invalid">
                      Period 2 is required
                    </Form.Control.Feedback>
                  </Form.Group>
                  {!isTwoPeriodsTariff && (
                    <>
                      <Form.Group className="mb-3">
                        <Form.Label>Period 3</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="kW"
                          name="period3"
                          value={values.period3}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          onFocus={handleFocus}
                        />
                        <Form.Control.Feedback type="invalid">
                          Period 3 is required
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Period 4</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="kW"
                          name="period4"
                          value={values.period4}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          onFocus={handleFocus}
                        />
                        <Form.Control.Feedback type="invalid">
                          Period 4 is required
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Period 5</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="kW"
                          name="period5"
                          value={values.period5}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          onFocus={handleFocus}
                        />
                        <Form.Control.Feedback type="invalid">
                          Period 5 is required
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Period 6</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="kW"
                          name="period6"
                          value={values.period6}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          onFocus={handleFocus}
                        />
                        <Form.Control.Feedback type="invalid">
                          Period 6 is required
                        </Form.Control.Feedback>
                      </Form.Group>
                    </>
                  )}
                </>
              )}
            </FadeTransition>
          </SwitchTransition>
        </Form>
      </Card.Body>
    </Card>
  );
};
