import React from 'react';
import { Card, Table, Dropdown, ButtonGroup, Button } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit, faCheck, faTimes, faBalanceScale, faCogs } from '@fortawesome/free-solid-svg-icons';
import { useCases } from '../context/CasesContext'; // Import the custom hook

export const CasesTable = ({ handleShowModal }) => { // Pass handleShowModal as a prop
  const { cases, deleteCase } = useCases(); // Get cases and delete function from the context

  const TableRow = (props) => {
    const { index, type, pv, bess, generators, loadsFlexibilitySizing, flexibilityRequestsSizing, assetsSize, loadsFlexibilityOperation, flexibilityRequestsOperation, hiredPower } = props;

    const handleRemove = () => {
      deleteCase(index); // Call the delete function with the index
    };

    const handleEdit = () => {
      handleShowModal("Case", { index, ...props }); // Pass the index and data to the modal
    };

    const renderIcon = (value) => {
      return value ? <FontAwesomeIcon icon={faCheck} className="text-success me-1" /> : <FontAwesomeIcon icon={faTimes} className="text-light me-1" />;
    };

    const renderType = (type) => {
      if (type === "sizing") {
        return (
          <>
            <FontAwesomeIcon icon={faBalanceScale} className="me-2" />
            {type.toUpperCase()}
          </>
        );
      } else if (type === "operation") {
        return (
          <>
            <FontAwesomeIcon icon={faCogs} className="me-2" />
            {type.toUpperCase()}
          </>
        );
      }
      return type;
    };

    const renderText = (text) => {
      return text ? text.toUpperCase() : 'Not Applicable';
    };

    return (
      <tr style={{ height: '50px' }}>
        <td className="border-0">
          <Dropdown as={ButtonGroup} alignRight>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="h6">{renderType(type) || ''}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={handleEdit}>
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
              </Dropdown.Item>
              <Dropdown.Item className="text-danger" onClick={handleRemove}>
                <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
        <td className="border-0">{renderIcon(pv)}</td>
        <td className="border-0">{renderIcon(bess)}</td>
        <td className="border-0">{renderIcon(generators)}</td>
        <td className="border-0">{renderIcon(loadsFlexibilitySizing)}</td>
        <td className="border-0">{renderIcon(flexibilityRequestsSizing)}</td>
        <td className="border-0">{renderText(assetsSize)}</td>
        <td className="border-0">{renderIcon(loadsFlexibilityOperation)}</td>
        <td className="border-0">{renderIcon(flexibilityRequestsOperation)}</td>
        <td className="border-0">{renderText(hiredPower)}</td>
      </tr>
    );
  };

  const rowsToFill = 6;
  const currentRows = cases.length;
  const placeholdersNeeded = rowsToFill - currentRows > 0 ? rowsToFill - currentRows : 0;

  return (
    <Card border="light" className="shadow-sm mb-4">
      <Card.Body className="pb-0">
        <Table responsive className="table-centered table-nowrap rounded mb-0">
          <thead className="thead-light">
            <tr>
              <th className="border-0">Type</th>
              <th className="border-0">PV</th>
              <th className="border-0">BESS</th>
              <th className="border-0">Generators</th>
              <th className="border-0">Loads Flexibility (Sizing)</th>
              <th className="border-0">Flexibility Requests (Sizing)</th>
              <th className="border-0">Assets Size</th>
              <th className="border-0">Loads Flexibility (Operation)</th>
              <th className="border-0">Flexibility Requests (Operation)</th>
              <th className="border-0">Hired Power</th>
            </tr>
          </thead>
          <tbody>
            {cases.length > 0 ? (
              <>
                {cases.map((caseItem, index) => (
                  <TableRow
                    key={index}
                    index={index}
                    {...caseItem}
                    handleShowModal={handleShowModal}
                  />
                ))}
                {Array.from({ length: placeholdersNeeded }).map((_, idx) => (
                  <tr key={`placeholder-${idx}`} style={{ height: '50px' }}>
                    <td className="border-0" colSpan="10"></td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td className="text-center" colSpan="10" style={{ height: '300px' }}>
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};