import React from "react";
import { Col, Row, Card, Form } from "@themesberg/react-bootstrap";
import { SwitchTransition, Transition } from "react-transition-group";
import styled from "styled-components";
import TextAreaWithLineNumbers from './TextAreaWithLineNumbers'; // Adjust the import path as necessary

const FadeDiv = styled.div`
  transition: 0.5s;
  opacity: ${({ state }) => (state === "entered" ? 1 : 0)};
  display: ${({ state }) => (state === "exited" ? "none" : "block")};
`;

const FadeTransition = ({ children, ...rest }) => (
  <Transition {...rest}>
    {state => <FadeDiv state={state}>{children}</FadeDiv>}
  </Transition>
);

export const GridBuyingForm = ({ values, setValues, validated }) => {
  const handleChange = (event) => {
    const { name, value } = event.target;

    // Perform regex check to ensure the value is a valid number or empty
    const isValidNumber = value === '' || /^\s*(?=.*[0-9])\d*(?:[.,]\d*)?\s*$/.test(value);

    // Update state only if the value is valid or the field is not the combobox
    if (isValidNumber || name === "priceOption") {
      setValues({
        ...values,
        [name]: value
      });
    }
  };

  const handleBlur = (event) => {
    const { name, value, placeholder } = event.target;

    // Perform regex check to ensure the value is a valid number or empty
    const isValidNumber = value === '' || /^\s*(?=.*[0-9])\d*(?:[.,]\d*)?\s*$/.test(value);

    if (isValidNumber && value !== '') {
      setValues({
        ...values,
        [name]: `${value} ${placeholder}`
      });
    } else if (!isValidNumber && name === "priceOption") {
      setValues({
        ...values,
        [name]: value
      });
    } else if (!isValidNumber) {
      setValues({
        ...values,
        [name]: ''
      });
    }
  };

  const handleFocus = (event) => {
    const { name, value } = event.target;
    
    // Remove any appended placeholder text
    const newValue = value.split(' ')[0];
    setValues({
      ...values,
      [name]: newValue
    });
  };

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Energy Information (Buying)</h5>
        <Form id="gridBuyingForm" noValidate validated={validated}>
          <Row>
            <Col md={12} className="mb-3">
              <Form.Group>
                <Form.Label>Price Option *</Form.Label>
                <Form.Select
                  required
                  name="priceOption"
                  value={values.priceOption}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                >
                  <option value="">Please Select</option>
                  <option value="Fixed">Fixed</option>
                  <option value="By Period">By Period</option>
                  <option value="Annual">Annual</option>
                  <option value="Upload Price">Upload Price</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Price Option is required
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <SwitchTransition mode="out-in">
            <FadeTransition
              key={values.priceOption}
              timeout={500}
              unmountOnExit
              mountOnEnter
            >
              {values.priceOption === "Fixed" ? (
                <Row>
                  <Col md={12} className="mb-3">
                    <Form.Group>
                      <Form.Label>Fixed Price *</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="€/kWh"
                        name="fixedPrice"
                        value={values.fixedPrice}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                      />
                      <Form.Control.Feedback type="invalid">
                        Fixed Price is required
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              ) : values.priceOption === "By Period" ? (
                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Group>
                      <Form.Label>Period 1 *</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="€/kWh"
                        name="period1"
                        value={values.period1}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                      />
                      <Form.Control.Feedback type="invalid">
                        Period 1 is required
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group>
                      <Form.Label>Period 2 *</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="€/kWh"
                        name="period2"
                        value={values.period2}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                      />
                      <Form.Control.Feedback type="invalid">
                        Period 2 is required
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group>
                      <Form.Label>Period 3 *</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="€/kWh"
                        name="period3"
                        value={values.period3}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                      />
                      <Form.Control.Feedback type="invalid">
                        Period 3 is required
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group>
                      <Form.Label>Period 4 *</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="€/kWh"
                        name="period4"
                        value={values.period4}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                      />
                      <Form.Control.Feedback type="invalid">
                        Period 4 is required
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group>
                      <Form.Label>Period 5 *</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="€/kWh"
                        name="period5"
                        value={values.period5}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                      />
                      <Form.Control.Feedback type="invalid">
                        Period 5 is required
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group>
                      <Form.Label>Period 6 *</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="€/kWh"
                        name="period6"
                        value={values.period6}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                      />
                      <Form.Control.Feedback type="invalid">
                        Period 6 is required
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              ) : values.priceOption === "Annual" ? (
                <Row>
                  <Col md={12} className="mb-3">
                    <Form.Group>
                      <Form.Label>Buying Price *</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="€/kWh"
                        name="annualPrice"
                        value={values.annualPrice}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                      />
                      <Form.Control.Feedback type="invalid">
                        Buying Price is required
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              ) : values.priceOption === "Upload Price" ? (
                <Row>
                  <Col md={12} className="mb-3">
                    <Form.Group>
                      <Form.Label>Time Series Data *</Form.Label>
                      <TextAreaWithLineNumbers
                        value={values.profile || ''}
                        onChange={handleChange}
                        placeholder="Enter time series data"
                        name="profile"
                      />
                      <Form.Control.Feedback type="invalid">
                        Profile is required
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              ) : null}
            </FadeTransition>
          </SwitchTransition>
        </Form>
      </Card.Body>
    </Card>
  );
};