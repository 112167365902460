import React, { useState } from "react";
import { Card, Form, Row, Col, Alert } from "@themesberg/react-bootstrap";
import { usePV } from "../context/PVContext"; // Import the custom hook
import { useBESS } from "../context/BESSContext";
import { useGENERATOR } from "../context/GENERATORContext";
import { useCriticalLoad } from "../context/Loads_CriticalContext"; // Import the custom hook
import { useDisconnectableLoad } from "../context/Loads_DisconnectableContext";
import { useReducibleLoad } from "../context/Loads_ReducibleContext";
import { usePowerShiftableLoad } from "../context/Loads_PowerShiftableContext";
import { useTimeShiftableLoad } from "../context/Loads_TimeShiftableContext";
import { useFlexibleRequests } from "../context/Requests_FlexibilityContext";

export const FormsCases = ({ values, setValues }) => {
  const { pvSystems } = usePV();
  const { bessSystems } = useBESS();
  const { generatorSystems } = useGENERATOR();
  const { criticalLoads } = useCriticalLoad();
  const { disconnectableLoads } = useDisconnectableLoad();
  const { reducibleLoads } = useReducibleLoad();
  const { powerShiftableLoads } = usePowerShiftableLoad();
  const { timeShiftableLoads } = useTimeShiftableLoad();
  const { flexibleRequests } = useFlexibleRequests();

  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value
    });
  };

  const handleRadioChange = (event) => {
    const { name, value } = event.target;

    if (value === "yes") {
      if (name === "pv" && pvSystems.length < 1) {
        setErrorMessage("You need at least one existing PV asset.");
        setValues({
          ...values,
          [name]: false
        });
        return;
      }
      if (name === "bess" && bessSystems.length < 1) {
        setErrorMessage("You need at least one existing BESS asset.");
        setValues({
          ...values,
          [name]: false
        });
        return;
      }
      if (name === "genset" && generatorSystems.length < 1) {
        setErrorMessage("You need at least one existing GENSET asset.");
        setValues({
          ...values,
          [name]: false
        });
        return;
      }
      if (name === "flexibleLoadsSizing" && (criticalLoads.length + disconnectableLoads.length + reducibleLoads.length + powerShiftableLoads.length + timeShiftableLoads.length) < 1) {
        setErrorMessage("You need at least one existing flexible load (critical, disconnectable, reducible, power shiftable, or time shiftable).");
        setValues({
          ...values,
          [name]: false
        });
        return;
      }
      if (name === "flexibleRequestSizing" && flexibleRequests.length < 1) {
        setErrorMessage("You need at least one existing flexibility request.");
        setValues({
          ...values,
          [name]: false
        });
        return;
      }
      if (name === "flexibleLoadsOperation" && (criticalLoads.length + disconnectableLoads.length + reducibleLoads.length + powerShiftableLoads.length + timeShiftableLoads.length) < 1) {
        setErrorMessage("You need at least one existing flexible load (critical, disconnectable, reducible, power shiftable, or time shiftable).");
        setValues({
          ...values,
          [name]: false
        });
        return;
      }
      if (name === "flexibleRequestOperation" && flexibleRequests.length < 1) {
        setErrorMessage("You need at least one existing flexibility request.");
        setValues({
          ...values,
          [name]: false
        });
        return;
      }
    }

    setErrorMessage("");
    setValues({
      ...values,
      [name]: value === "yes"
    });
  };

  const handleAssetSizeChange = (event) => {
    const { name, value } = event.target;

    if (value === "PV" && pvSystems.length < 1) {
      setErrorMessage("You need at least one existing PV asset.");
      setValues({
        ...values,
        [name]: ""
      });
      return;
    }
    if (value === "BESS" && bessSystems.length < 1) {
      setErrorMessage("You need at least one existing BESS asset.");
      setValues({
        ...values,
        [name]: ""
      });
      return;
    }
    if (value === "GENSET" && generatorSystems.length < 1) {
      setErrorMessage("You need at least one existing GENSET asset.");
      setValues({
        ...values,
        [name]: ""
      });
      return;
    }

    setErrorMessage("");
    setValues({
      ...values,
      [name]: value
    });
  };

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
        <Form id="casesForm" noValidate>
          <Row className="align-items-center mb-3">
            <Col md={12}>
              <Form.Group>
                <Form.Label>Type</Form.Label>
                <Form.Select name="type" value={values.type} onChange={handleChange}>
                  <option value="">Select</option>
                  <option value="sizing">Sizing</option>
                  <option value="operation">Operation</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          {values.type === "sizing" && (
            <>
              <Row className="align-items-center mb-3">
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>PV</Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        name="pv"
                        value="yes"
                        checked={values.pv === true}
                        onChange={handleRadioChange}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        name="pv"
                        value="no"
                        checked={values.pv === false}
                        onChange={handleRadioChange}
                      />
                    </div>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>BESS</Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        name="bess"
                        value="yes"
                        checked={values.bess === true}
                        onChange={handleRadioChange}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        name="bess"
                        value="no"
                        checked={values.bess === false}
                        onChange={handleRadioChange}
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="align-items-center mb-3">
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>GENSET</Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        name="genset"
                        value="yes"
                        checked={values.genset === true}
                        onChange={handleRadioChange}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        name="genset"
                        value="no"
                        checked={values.genset === false}
                        onChange={handleRadioChange}
                      />
                    </div>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>FLEXIBLE LOADS SIZING</Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        name="flexibleLoadsSizing"
                        value="yes"
                        checked={values.flexibleLoadsSizing === true}
                        onChange={handleRadioChange}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        name="flexibleLoadsSizing"
                        value="no"
                        checked={values.flexibleLoadsSizing === false}
                        onChange={handleRadioChange}
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="align-items-center mb-3">
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>FLEXIBLE REQUEST SIZING</Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        name="flexibleRequestSizing"
                        value="yes"
                        checked={values.flexibleRequestSizing === true}
                        onChange={handleRadioChange}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        name="flexibleRequestSizing"
                        value="no"
                        checked={values.flexibleRequestSizing === false}
                        onChange={handleRadioChange}
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            </>
          )}

          {values.type === "operation" && (
            <>
              <Row className="align-items-center mb-3">
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Asset Size</Form.Label>
                    <Form.Select name="assetSize" value={values.assetSize} onChange={handleAssetSizeChange}>
                      <option value="">Select</option>
                      <option value="PV">PV</option>
                      <option value="BESS">BESS</option>
                      <option value="GENSET">GENSET</option>
                      <option value="hiredPower">Hired Power</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Hired Power</Form.Label>
                    <Form.Select name="hiredPower" value={values.hiredPower} onChange={handleChange}>
                      <option value="">Select</option>
                      <option value="fixed">Fixed</option>
                      <option value="optimized">Optimized</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="align-items-center mb-3">
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>FLEXIBLE LOADS OPERATION</Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        name="flexibleLoadsOperation"
                        value="yes"
                        checked={values.flexibleLoadsOperation === true}
                        onChange={handleRadioChange}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        name="flexibleLoadsOperation"
                        value="no"
                        checked={values.flexibleLoadsOperation === false}
                        onChange={handleRadioChange}
                      />
                    </div>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>FLEXIBLE REQUEST OPERATION</Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        name="flexibleRequestOperation"
                        value="yes"
                        checked={values.flexibleRequestOperation === true}
                        onChange={handleRadioChange}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        name="flexibleRequestOperation"
                        value="no"
                        checked={values.flexibleRequestOperation === false}
                        onChange={handleRadioChange}
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            </>
          )}
        </Form>
      </Card.Body>
    </Card>
  );
};