import React, { useState, useEffect } from "react";
import { Col, Row, Card, Form } from '@themesberg/react-bootstrap';

export const ProjectFinancialsForm = ( {setFinancialData} ) => {
  const [values, setValues] = useState({
    cashChange: '',
    operationCost: '',
    discountRate: '',
    projectLifetime: '',
    inflationRate: '',
    priceCorrectionFactor: '',
    year: ''
  });

  useEffect(() => {
    const savedValues = JSON.parse(localStorage.getItem("projectData")) || {};
    setValues(savedValues);
  }, []);

  useEffect(() => {
    localStorage.setItem("projectData", JSON.stringify(values));
  }, [values]);


  const handleChange = (event) => {
    const { name, value } = event.target;

    // Update state directly
    setValues((prevValues) => {
      const newValues = {
        ...prevValues,
        [name]: value,
      };

      // If address-related fields change, update the parent component
      if (["cashChange", "operationCost", "discountRate", "projectLifetime", "inflationRate", "addressNumber", "priceCorrectionFactor", "year",].includes(name)) {
        setFinancialData({
          cashChange: newValues.cashChange,
          operationCost: newValues.operationCost,
          discountRate: newValues.discountRate,
          projectLifetime: newValues.projectLifetime,
          inflationRate: newValues.inflationRate,
          addressNumber: newValues.addressNumber,
          priceCorrectionFactor: newValues.priceCorrectionFactor,
          year: newValues.year,
        });
      }

      return newValues;
    });
  };

  const handleBlur = (event) => {
    const { name, value, placeholder } = event.target;

    // Perform regex check to ensure the value is a valid number or empty
    const isValidNumber = value === '' || /^\s*(?=.*[0-9])\d*(?:[.,]\d*)?\s*$/.test(value);

    if (isValidNumber && value !== '') {
      setValues({
        ...values,
        [name]: `${value} ${placeholder}`
      });
    } else if (!isValidNumber) {
      setValues({
        ...values,
        [name]: ''
      });
    }
  };

  const handleFocus = (event) => {
    const { name, value } = event.target;
    
    // Remove any appended placeholder text
    const newValue = value.split(' ')[0];
    setValues({
      ...values,
      [name]: newValue
    });
  };

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Project Financials</h5>
        <Form>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Cash Change *</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="€/ $"
                  name="cashChange"
                  value={values.cashChange}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Operation Cost of the Reference Case</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="k€/year"
                  name="operationCost"
                  value={values.operationCost}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Discount Rate *</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="%"
                  name="discountRate"
                  value={values.discountRate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Project Lifetime *</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="years"
                  name="projectLifetime"
                  value={values.projectLifetime}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Inflation Rate *</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="%"
                  name="inflationRate"
                  value={values.inflationRate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Price Correction Factor of the Energy Purchased from the Main Grid (Annual Variation)</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="%"
                  name="priceCorrectionFactor"
                  value={values.priceCorrectionFactor}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Year *</Form.Label>
                <Form.Select
                  name="year"
                  value={values.year}
                  onChange={handleChange}
                  // onBlur={handleBlur}
                  onFocus={handleFocus}
                  defaultValue="0"
                >
                  <option value="0">Please Select</option>
                  <option value="2017">2017</option>
                  <option value="2018">2018</option>
                  <option value="2019">2019</option>
                  <option value="2021">2021</option>
                  <option value="2022">2022</option>
                  <option value="2023">2023</option>
                  <option value="2024">2024</option>
                  <option value="2025">2025</option>
                  {/* Add more years as needed */}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};
