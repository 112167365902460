import React from "react";
import { Card, Form, Row, Col } from "@themesberg/react-bootstrap";

const RenewableShareConstraintsForm = ({ values, setValues }) => {
  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value
    });
  };

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Renewable Share Constraints</h5>
        <Form>
          <Row className="align-items-center mb-3">
            <Col md={12}>
              <Form.Group>
                <Form.Label>Minimum Share of Renewables (Annual Generation) *</Form.Label>
                <Form.Control
                  type="text"
                  name="renewableShare"
                  value={values.renewableShare}
                  onChange={handleChange}
                  placeholder="%"
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default RenewableShareConstraintsForm;