export const generatorModels = [
  "CAT  3516B [1600kW]",
  "CAT C175-16 2725kW Prime",
  "CAT C18 ATAAC 550kW",
  "CAT C4.4-SP 60Hz 40ekW",
  "CAT C9 ATAAC 200ekW",
  "CAT 3516A TA [1750kW]",
  "CAT 3516C 2000ekW",
  "Cummins 125GGHJ [125kW]",
  "Cummins 45GGPC-6126A [45kW]",
  "Cummins 50GGPC-6128A [50kW]",
  "Cummins RS100 C100N6 [100kW]",
  "Cummins RS22 C22 N6 [22kW]",
  "Cummins RX30 C30 N6H [30kW]",
  "Cummins RX60 C60 N6H [60kW]",
  "Cummins DSGAA [100kW]",
  "Generac Protector 15kW",
  "Generac Protector 20kW",
  "Generac Protector 30kW",
  "Generac Protector 50kW",
  "Generac SD050 [50kW]",
  "Generac SD100 [100kW]",
  "Generac SD150 [150kW]",
  "Innovus VSG600",
  "Innovus VSG1200",
  "Innovus VSG1800",
  "Innovus VSG2400",
  "Danvest 1088kWe Low Load Hybrid Power Plant",
  "Danvest 131kWe Low Load Hybrid Power Plant",
  "Danvest 648kWe Low Load Hybrid Power Plant",
  "Autosize Genset",
  "Generic Prime Power - Diesel [725kW]",
  "Generic Fuel Cell  - Natural Gas (CHP)",
  "Generic Generator - Biogas",
  "Generic Microturbine - Natural Gas (CHP)",
  "10kW Genset - Diesel",
  "50kW Genset - Diesel",
  "100kW Genset - Diesel",
  "500kW Genset - Diesel",
  "2000kW Genset - Diesel",
  "100 kW Genset with Biogas Cofire",
  "Kohler 28kW Prime/Continuous",
  "Kohler 30kW Standby",
  "Kohler 48kW Standby",
  "Kohler 89kW Continuous",
  "Kohler 96kW Continuous",
  "Kohler 105kW Standby",
  "Kohler 137kW Continuous",
  "Kohler 139kW Prime",
  "Kohler 154kW Standby",
  "Kohler 360kW Standby",
  "Kohler 410kW Standby",
  "2gEnergy Avus 800 (CHP)",
  "2gEnergy Agenitor 206 (CHP)",
  "Cummins QSK60",
  "Cummins QSK90",
  "Capstone C1000",
  "Capstone C200",
  "RETScreen Diesel 100 kW",
  "RETScreen Diesel 1000 kW",
  "RETScreen Diesel 10000 kW",
  "RETScreen Diesel 100000 kW",
  "RETScreen Natural gas 100 kW",
  "RETScreen Natural gas 1000 kW",
  "RETScreen Natural gas 10000 kW",
  "RETScreen Natural gas 100000 kW",
  "RETScreen Biogas 10 kW",
  "RETScreen Biogas 100 kW",
  "RETScreen Biogas 1000 kW",
  "RETScreen Biogas 10000 kW",
  "RETScreen Biogas 100000 kW"
];