// pages/microgrid/Renewables.js
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Button, Modal, Alert } from "@themesberg/react-bootstrap";
import { PVTable } from "../../components/Tables-pv";
import { BESSTable } from "../../components/Tables-bess";
import { GENERATORTable } from "../../components/Tables-generator";
import { PVForm } from "../../components/Forms-pv";
import { BESSForm } from "../../components/Forms-bess";
import { GENERATORForm } from "../../components/Forms-generator";
import { usePV } from "../../context/PVContext"; // Import the custom hook
import { useBESS } from "../../context/BESSContext";
import { useGENERATOR } from "../../context/GENERATORContext";
import axios from "axios"; // ADD
import { useHistory } from "react-router-dom"; // ADD
import { ThreeDots } from "react-loader-spinner"; // ADD
import "./Renewables.css";

export default () => {
  // Contexts
  const { pvSystems, addPvSystem, updatePvSystem } = usePV();
  const { bessSystems, addBessSystem, updateBessSystem } = useBESS(); // Include updateBessSystem
  const { generatorSystems, addGeneratorSystem, updateGeneratorSystem } = useGENERATOR(); // Include updateGeneratorSystem

  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [validated, setValidated] = useState(false);
  const [validated_bess, setValidated_bess] = useState(false);
  const [validated_generator, setValidated_generator] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); // ADD
  const [successMessage, setSuccessMessage] = useState(""); // ADD
  const [loading, setLoading] = useState(false); // ADD
  const history = useHistory(); // ADD

  const [values, setValues] = useState({
    name: '',
    model: '',
    pvInstalledCapacity: '',
    pvInstallCapacityToAdd: '',
    newPvCanBeAdded: '',
    fixedIncentive: '',
    forecast: '',
  });

  const [values_bess, setValues_bess] = useState({
    name: '',
    model: '',
    bessInstalledCapacity: '',
    bessInstallCapacityToAdd: '',
    newBessCanBeAdded: '',
    fixedIncentive: '',
  });

  const [values_generator, setValues_generator] = useState({
    name: '',
    model: '',
    generatorInstalledCapacity: '',
    generatorInstallCapacityToAdd: '',
    newGeneratorCanBeAdded: '',
    fixedIncentive: '',
    generatorUse: '',
  });

  const [editIndex, setEditIndex] = useState(null); // Track the index of the item being edited

  const handleShowModal = (title, data) => {
    setModalTitle(title);
    if (data) {
      setEditIndex(data.index);
      if (title === "+ Photovoltaic") {
        setValues(data);
      } else if (title === "+ Battery / Energy Storage") {
        setValues_bess(data);
      } else if (title === "+ Generator") {
        setValues_generator(data);
      }
    }
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEditIndex(null);
    setValues({
      name: '',
      model: '',
      pvInstalledCapacity: '',
      pvInstallCapacityToAdd: '',
      newPvCanBeAdded: '',
      fixedIncentive: '',
      forecast: '',
    });
    setValues_bess({
      name: '',
      model: '',
      bessInstalledCapacity: '',
      bessInstallCapacityToAdd: '',
      newBessCanBeAdded: '',
      fixedIncentive: '',
    });
    setValues_generator({
      name: '',
      model: '',
      generatorInstalledCapacity: '',
      generatorInstallCapacityToAdd: '',
      newGeneratorCanBeAdded: '',
      fixedIncentive: '',
      generatorUse: '',
    });
  };

  const handleSave = () => {
    if (modalTitle === "+ Photovoltaic") {
      handleSave_pv();
    } else if (modalTitle === "+ Battery / Energy Storage") {
      handleSave_bess();
    } else if (modalTitle === "+ Generator") {
      handleSave_generator();
    }
  };

  const handleSave_pv = () => {
    const form = document.querySelector("#pvForm");
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      if (editIndex !== null) {
        updatePvSystem(editIndex, values); // Update the existing PV system
      } else {
        addPvSystem(values); // Add a new PV system
      }
      console.log("PV system saved: ", values);
      setValues({
        name: '',
        model: '',
        pvInstalledCapacity: '',
        pvInstallCapacityToAdd: '',
        newPvCanBeAdded: '',
        fixedIncentive: '',
        forecast: '',
      });
      setShowModal(false);
      setValidated(false);
      setEditIndex(null);
    }
  };

  const handleSave_bess = () => {
    const form = document.querySelector("#bessForm");
    if (form.checkValidity() === false) {
      setValidated_bess(true);
    } else {
      if (editIndex !== null) {
        updateBessSystem(editIndex, values_bess); // Update the existing BESS system
      } else {
        addBessSystem(values_bess); // Add a new BESS system
      }
      console.log("BESS system saved: ", values_bess);
      setValues_bess({
        name: '',
        model: '',
        bessInstalledCapacity: '',
        bessInstallCapacityToAdd: '',
        newBessCanBeAdded: '',
        fixedIncentive: '',
      });
      setShowModal(false);
      setValidated_bess(false);
      setEditIndex(null);
    }
  };

  const handleSave_generator = () => {
    const form = document.querySelector("#generatorForm");
    if (form.checkValidity() === false) {
      setValidated_generator(true);
    } else {
      if (editIndex !== null) {
        updateGeneratorSystem(editIndex, values_generator); // Update the existing Generator system
      } else {
        addGeneratorSystem(values_generator); // Add a new Generator system
      }
      console.log("Generator system saved: ", values_generator);
      setValues_generator({
        name: '',
        model: '',
        generatorInstalledCapacity: '',
        generatorInstallCapacityToAdd: '',
        newGeneratorCanBeAdded: '',
        fixedIncentive: '',
        generatorUse: '',
      });
      setShowModal(false);
      setValidated_generator(false);
      setEditIndex(null);
    }
  };

  const handleRunClick = async () => {
    console.log('PV Systems:', JSON.stringify(pvSystems, null, 2));
    console.log('BESS Systems:', JSON.stringify(bessSystems, null, 2));
    console.log('Generator Systems:', JSON.stringify(generatorSystems, null, 2));
  
    const data = {
      PVAsset: pvSystems,
      BESSAsset: bessSystems,
      GENSETAsset: generatorSystems
    };
    console.log('Saved Data:', JSON.stringify(data, null, 2));
  
    setErrorMessage("");
    setLoading(true);
  
    try {
      const apiUrl = process.env.REACT_APP_API_URL; // Use the API URL from the environment variables
      const response = await axios.post(`${apiUrl}/load_chamber_any_json`, data, {
        timeout: 5000 // 5 seconds timeout
      });
  
      if (response.status === 200) {
        console.log("Data successfully sent to the server.");
        setSuccessMessage("Data successfully saved.");
        setTimeout(() => {
          setSuccessMessage("");
          setLoading(false);
          history.push("/microgrid/loads"); // Navigate to the Loads route
        }, 3000);
      } else {
        setErrorMessage("Server not responsive");
        setLoading(false);
      }
    } catch (error) {
      setErrorMessage("Server not responsive");
      setLoading(false);
    }
  
    setTimeout(() => {
      setErrorMessage("");
    }, 3000);
  };
  

  return (
    <>
      {loading && ( 
        <div className="loading-overlay"> 
          <ThreeDots color="#00BFFF" height={80} width={80} /> 
        </div> 
      )}
      <Row className="align-items-center mt-4 mb-4">
        <Col>
          <h4>Assets</h4>
        </Col>
        <Col className="text-right">
          <Button
            variant="success"
            type="button"
            className="me-2"
            onClick={handleRunClick}
            disabled={loading} // Disable button while loading
          >
            <FontAwesomeIcon icon={faArrowRight} className="me-2" />
            <span>Next</span>
          </Button>
        </Col>
      </Row>
      {errorMessage && (
        <Row>
          <Col>
            <Alert variant="danger">{errorMessage}</Alert>
          </Col>
        </Row>
      )}
      {successMessage && (
        <Row>
          <Col>
            <Alert variant="success">{successMessage}</Alert>
          </Col>
        </Row>
      )}
      <Row className="align-items-center mt-4 mb-4">
        <Col>
          <h4 className="centered-heading">Photovoltaic Resources</h4>
        </Col>
        <Col xs="auto">
          <Button
            variant="secondary"
            type="button"
            className="text-dark me-2"
            onClick={() => handleShowModal("+ Photovoltaic")}
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            <span>New</span>
          </Button>
        </Col>
      </Row>
      <PVTable className="mb-4" handleShowModal={handleShowModal} />
      <Row className="align-items-center mt-4 mb-4">
        <Col>
          <h4 className="centered-heading">Battery / Energy Storage Resources</h4>
        </Col>
        <Col xs="auto">
          <Button
            variant="secondary"
            className="text-dark me-2"
            onClick={() => handleShowModal("+ Battery / Energy Storage")}
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            <span>New</span>
          </Button>
        </Col>
      </Row>
      <BESSTable className="mb-4" handleShowModal={handleShowModal} />
      <Row className="align-items-center mt-4 mb-4">
        <Col>
          <h4 className="centered-heading">Generator Resources</h4>
        </Col>
        <Col xs="auto">
          <Button
            variant="secondary"
            className="text-dark me-2"
            onClick={() => handleShowModal("+ Generator")}
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            <span>New</span>
          </Button>
        </Col>
      </Row>
      <GENERATORTable className="mb-4" handleShowModal={handleShowModal} />

      <Modal as={Modal.Dialog} centered show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header>
          <Modal.Title className="h6">{modalTitle}</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleCloseModal} />
        </Modal.Header>
        <Modal.Body>
          {modalTitle === "+ Photovoltaic" ? (
            <PVForm values={values} setValues={setValues} validated={validated} />
          ) : modalTitle === "+ Battery / Energy Storage" ? (
            <BESSForm values={values_bess} setValues={setValues_bess} validated={validated_bess} />
          ) : (
            <GENERATORForm values={values_generator} setValues={setValues_generator} validated={validated_generator} />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleSave}>
            Save
          </Button>
          <Button variant="link" className="text-gray ms-auto" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};