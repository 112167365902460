import React, { useEffect, useRef } from "react";
import Chartist from "react-chartist";
import Plot from 'react-plotly.js';
import './Charts.css'; // Import custom CSS for Chartist

export const SalesValueChart = () => {
  const data = {
    labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    series: [[1, 2, 2, 3, 3, 4, 3]]
  };

  const options = {
    low: 0,
    showArea: true,
    fullWidth: true,
    axisX: {
      position: 'end',
      showGrid: true
    },
    axisY: {
      // On the y-axis start means left and end means right
      showGrid: false,
      showLabel: false,
      labelInterpolationFnc: value => `$${value / 1}k`
    }
  };

  return (
    <Chartist data={data} options={options} type="Line" className="ct-series-g ct-double-octave" />
  );
};


export const ProfileChart = () => {
  // Generate hourly data for an entire year
  const generateHourlyData = () => {
    const data = [];
    for (let i = 0; i < 8760; i++) {
      data.push(Math.floor(Math.random() * 100)); // Random data between 0 and 100
    }
    return data;
  };

  const data = {
    labels: Array.from({ length: 8760 }, (_, i) => i),
    series: [
      generateHourlyData(), // Series 1 data
      generateHourlyData()  // Series 2 data
    ]
  };

  const options = {
    low: 0,
    showArea: true,
    fullWidth: true,
    showPoint: false, // Disable points
    axisX: {
      position: 'end',
      showGrid: true,
      labelInterpolationFnc: (value, index) => {
        // Display only a few labels to prevent cluttering
        return index % 720 === 0 ? value : null; // Show every 720th hour
      }
    },
    axisY: {
      showGrid: false,
      showLabel: true,
      labelInterpolationFnc: value => `${value}kW`
    }
  };

  return (
    <div>
      <Chartist data={data} options={options} type="Line" className="ct-series-g ct-double-octave custom-chartist-chart" />
      <div className="chart-legend">
        <span className="legend-item" style={{ color: '#1f77b4' }}>Baseline</span>
        <span className="legend-item" style={{ color: '#ff7f0e' }}>Electric Vehicle</span>
      </div>
    </div>
  );
};

export const ProfileChart3 = () => {
  // Generate hourly data for an entire year
  const generateHourlyData = () => {
    const data = [];
    for (let i = 0; i < 8760; i++) {
      data.push(Math.floor(Math.random() * 100)); // Random data between 0 and 100
    }
    return data;
  };

  const data1 = generateHourlyData();
  const data2 = generateHourlyData();

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Plot
        data={[
          {
            x: Array.from({ length: 8760 }, (_, i) => i + 1),
            y: data1,
            type: 'scatter',
            mode: 'lines',
            name: 'Baseline',
            line: { color: '#1f77b4' } // Match the color of Series 1 in ProfileChart.css
          },
          {
            x: Array.from({ length: 8760 }, (_, i) => i + 1),
            y: data2,
            type: 'scatter',
            mode: 'lines',
            name: 'Electric Vehicle',
            line: { color: '#ff7f0e' } // Match the color of Series 2 in ProfileChart.css
          }
        ]}
        layout={{
          autosize: true,
          margin: {
            l: 50, // left margin
            r: 50, // right margin
            b: 50, // bottom margin
            t: 25, // top margin reduced
            pad: 4
          },
          xaxis: {
            title: 'Hour',
            showgrid: true,
            zeroline: false
          },
          yaxis: {
            title: 'Value',
            showline: false,
            range: [0, 100] // Ensure the y-axis covers the full range of values
          },
          legend: {
            x: 1,
            y: 0,
            xanchor: 'right',
            yanchor: 'bottom',
            orientation: 'h', // Set legend to horizontal orientation if you prefer
          }
        }}
        useResizeHandler={true}
        style={{ width: '100%', height: '100%' }}
        config={{
          scrollZoom: true,
          responsive: true
        }}
      />
    </div>
  );
};


export const SalesValueChartphone = () => {
  const data = {
    labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    series: [[1, 2, 2, 3, 3, 4, 3]]
  };

  const options = {
    low: 0,
    showArea: true,
    fullWidth: false,
    axisX: {
      position: 'end',
      showGrid: true
    },
    axisY: {
      // On the y-axis start means left and end means right
      showGrid: false,
      showLabel: false,
      labelInterpolationFnc: value => `$${value / 1}k`
    }
  };

  return (
    <Chartist data={data} options={options} type="Line" className="ct-series-g ct-major-tenth" />
  );
};

export const CircleChart = (props) => {
  const { series = [], donutWidth = 20 } = props;
  const sum = (a, b) => a + b;

  const options = {
    low: 0,
    high: 8,
    donutWidth,
    donut: true,
    donutSolid: true,
    fullWidth: false,
    showLabel: false,
    labelInterpolationFnc: value => `${Math.round(value / series.reduce(sum) * 100)}%`,
  };

  return (
    <Chartist data={{ series }} options={options} type="Pie" className="ct-golden-section" />
  );
};

export const BarChart = (props) => {
  const { labels = [], series = [], chartClassName = "ct-golden-section" } = props;
  const data = { labels, series };

  const options = {
    low: 0,
    showArea: true,
    axisX: {
      position: 'end'
    },
    axisY: {
      showGrid: false,
      showLabel: false,
      offset: 0
    }
  };

  return (
    <Chartist data={data} options={options} type="Bar" className={chartClassName} />
  );
};
