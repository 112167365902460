import React, { createContext, useState, useContext } from "react";

// Create a context with default value
const TimeShiftableLoadContext = createContext([]);

// Provider component
export const TimeShiftableLoadProvider = ({ children }) => {
  const [timeShiftableLoads, setTimeShiftableLoads] = useState([]);

  const addTimeShiftableLoad = (timeShiftableLoad) => {
    setTimeShiftableLoads([...timeShiftableLoads, timeShiftableLoad]);
  };

  const deleteTimeShiftableLoad = (index) => {
    setTimeShiftableLoads(timeShiftableLoads.filter((_, i) => i !== index));
  };

  const updateTimeShiftableLoad = (index, updatedTimeShiftableLoad) => {
    setTimeShiftableLoads(timeShiftableLoads.map((timeShiftableLoad, i) => (i === index ? updatedTimeShiftableLoad : timeShiftableLoad)));
  };

  return (
    <TimeShiftableLoadContext.Provider value={{ timeShiftableLoads, addTimeShiftableLoad, deleteTimeShiftableLoad, updateTimeShiftableLoad }}>
      {children}
    </TimeShiftableLoadContext.Provider>
  );
};

// Custom hook to use the Time Shiftable Load context
export const useTimeShiftableLoad = () => {
  return useContext(TimeShiftableLoadContext);
};