import React from "react";
import { Col, Row, Card, Form } from "@themesberg/react-bootstrap";
import { pvModels } from "../data/models-pv"; // Adjust the path as necessary
import { SwitchTransition, Transition } from "react-transition-group";
import styled from "styled-components";
import { ChoosePhotoWidget } from "../components/Widgets"; // Import your widget
import TextAreaWithLineNumbers from './TextAreaWithLineNumbers';

const FadeDiv = styled.div`
  transition: 0.5s;
  opacity: ${({ state }) => (state === "entered" ? 1 : 0)};
  display: ${({ state }) => (state === "exited" ? "none" : "block")};
`;

const FadeTransition = ({ children, ...rest }) => (
  <Transition {...rest}>
    {state => <FadeDiv state={state}>{children}</FadeDiv>}
  </Transition>
);

export const PVForm = ({ values, setValues, validated }) => {
  const handleChange = (event) => {
    const { name, value } = event.target;

    // Perform regex check to ensure the value is a valid number or empty
    const isValidNumber = value === '' || /^\s*(?=.*[0-9])\d*(?:[.,]\d*)?\s*$/.test(value);

    // Update state only if the value is valid or the field is not the combobox
    if (isValidNumber || name === "newPvCanBeAdded" || name === "model" || name === "name" || name === "forecast" || name === "PVtimeseriesData") {
      setValues({
        ...values,
        [name]: value
      });
    }
  };


  const handleBlur = (event) => {
    const { name, value, placeholder} = event.target;

    // Perform regex check to ensure the value is a valid number or empty
    const isValidNumber = value === '' || /^\s*(?=.*[0-9])\d*(?:[.,]\d*)?\s*$/.test(value);

    if (isValidNumber && value !== '') {
      setValues({
        ...values,
        [name]: `${value} ${placeholder}`
      });
    } else if (!isValidNumber && (name === "newPvCanBeAdded" || name === "model" || name === "name" || name === "forecast" || name === "PVtimeseriesData")) {
      setValues({
        ...values,
        [name]: value
      });
    } else if (!isValidNumber) {
      setValues({
        ...values,
        [name]: ''
      });
    }
  };

  const handleFocus = (event) => {
    const { name, value } = event.target;
    
    // Remove any appended placeholder text
    const newValue = value.split(' ')[0];
    setValues({
      ...values,
      [name]: newValue
    });
  };


  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <Form id="pvForm" noValidate validated={validated}>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Name *</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="PV System"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                />
                <Form.Control.Feedback type="invalid">
                  Name is required
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>PV Installed Capacity Already In the System *</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="kW"
                  name="pvInstalledCapacity"
                  value={values.pvInstalledCapacity}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                />
                <Form.Control.Feedback type="invalid">
                  PV Installed Capacity is required
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Model *</Form.Label>
                <Form.Select
                  required
                  name="model"
                  value={values.model}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                >
                  <option value="">No PV Selected</option>
                  {pvModels.map((model, index) => (
                    <option key={index} value={model}>{model}</option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Model is required
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>PV Install Capacity to Add (Assuming Fixed)</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="kW"
                  name="pvInstallCapacityToAdd"
                  value={values.pvInstallCapacityToAdd}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                />
                <Form.Control.Feedback type="invalid">
                  PV Install Capacity to Add is required
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>New PV Can Be Added? *</Form.Label>
                <Form.Select
                  required
                  name="newPvCanBeAdded"
                  value={values.newPvCanBeAdded}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                >
                  <option value="">Please Select</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  New PV Can Be Added? is required
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Fixed Incentive *</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="€/kW"
                  name="fixedIncentive"
                  value={values.fixedIncentive}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                />
                <Form.Control.Feedback type="invalid">
                  Fixed Incentive is required
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Forecast *</Form.Label>
                <Form.Select
                  required
                  name="forecast"
                  value={values.forecast}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                >
                  <option value="">Please Select</option>
                  <option value="PVGIS">Import from API (PVGIS)</option>
                  <option value="File">Upload File</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Forecast is required
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <SwitchTransition mode="out-in">
            <FadeTransition
              key={values.forecast}
              timeout={500}
              unmountOnExit
              mountOnEnter
            >
              {values.forecast === "PVGIS" ? (
                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Group>
                      <Form.Label>Latitude *</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="°"
                        name="latitude"
                        value={values.latitude}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                      />
                      <Form.Control.Feedback type="invalid">
                        Latitude is required
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group>
                      <Form.Label>Longitude *</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="°"
                        name="longitude"
                        value={values.longitude}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                      />
                      <Form.Control.Feedback type="invalid">
                        Longitude is required
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group>
                      <Form.Label>Slope *</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="°"
                        name="slope"
                        value={values.slope}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                      />
                      <Form.Control.Feedback type="invalid">
                        Slope is required
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group>
                      <Form.Label>Azimuth *</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="°"
                        name="azimuth"
                        value={values.azimuth}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                      />
                      <Form.Control.Feedback type="invalid">
                        Azimuth is required
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              ) : values.forecast === "File" ? (
                <Row>
                <Col md={12} className="mb-3">
                <Form.Group>
                      <Form.Label>Time Series Data</Form.Label>
                      <TextAreaWithLineNumbers
                  value={values.PVtimeseriesData || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  placeholder="Copy and Paste Time Series Data Here"
                  name="PVtimeseriesData"
                />
                      <Form.Control.Feedback type="invalid">
                        PV time series data is required
                      </Form.Control.Feedback>
                    </Form.Group>
            </Col>
                </Row>
              ) : null}
            </FadeTransition>
          </SwitchTransition>
        </Form>
      </Card.Body>
    </Card>
  );
};