import React from 'react';
import { Card } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { ChartTimeSeries } from './Charts-timeseries'; // Adjust the import path as necessary
import { useTimeSeries } from '../context/TimeSeriesContext'; // Import the custom hook
import { useEV } from '../context/EVContext'; // Import the custom hook

export const ProfileWidget = ({ percentageColor }) => {
  const { timeSeriesData } = useTimeSeries(); // Use the time series context
  const { evData } = useEV(); // Use the EV context

  const baselineCheckColor = timeSeriesData.baseline ? 'text-success' : 'text-white'; // Use 'text-muted' for lighter gray
  const evCheckColor = evData.EV == '1' ? 'text-success' : 'text-white'; // Check if EV timeSeries is not empty

  return (
    <Card border="light" className="shadow-sm" style={{ minHeight: '400px' }}> {/* Set minimum height */}
      <Card.Header className="d-flex justify-content-between align-items-center">
        <small className="fw-bold mt-2">
          <span className="me-2">Baseline</span>
          <FontAwesomeIcon icon={faCheck} className={`${baselineCheckColor} me-1`} />
          <span className="me-2">Electric Vehicle</span>
          <FontAwesomeIcon icon={faCheck} className={`${evCheckColor} me-1`} />
        </small>
      </Card.Header>
      <Card.Body className="p-2 h-80">
        <ChartTimeSeries />
      </Card.Body>
    </Card>
  );
};

export default ProfileWidget;