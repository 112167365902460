export const pvModels = [
  "PV h2glass",
  "General PV",
  "Generic flat plate PV",
  "CanadianSolar DYMOND CS6X-315",
  "CanadianSolar DYMOND CS6X-320",
  "CanadianSolar DYMOND CS6X-320",
  "CanadianSolar MAXPOWER CS6X-310",
  "CanadianSolar MAXPOWER CS6X-315",
  "CanadianSolar MAXPOWER CS6X-320",
  "Concentrating PV",
  "First Solar Series 4-107",
  "First Solar Series 5-375",
  "Hanwha Q.plus BFR-G4.1",
  "JA Jam6 60-270",
  "JA Jam6(K) 48-235",
  "Jinko JKM275-60",
  "Jinko Eagle PERC60 300W",
  "Kyocera KD 145 SX-UFU",
  "Kyocera KU325-8BCA",
  "Sharp ND-250QCS",
  "SunPower E20-327",
  "SunPower X21-335-BLK",
  "Trina Allmax M+",
  "Trina Allmax PD05",
  "Trina Duomax PEG14",
  "Trina Tallmax 1500V",
  "Trina Tallmax M+",
  "First Solar FS-3100",
  "First Solar FS-4115-2",
  "First Solar FS-4117-2",
  "First Solar FS-4122",
  "First Solar FS-4105-2",
  "SunPower P17-335-COM",
  "SunPower P17-340-COM",
  "SunPower P17-345-COM",
  "SunPower P17-350-COM",
  "SunPower P17-355-COM",
  "Yingli Panda 210C-24b",
  "Yingli Panda 260C-30b",
  "Yingli Panda 280C-30b",
  "Yingli 200 P-23b",
  "Yingli 250 P-29b",
  "Yingli 300 P-35b",
  "Trina Duomax 250PEG5",
  "Trina Honey plus PC05A(II) 280",
  "RETScreen 10kW",
  "RETScreen 100kW",
  "RETScreen 1000kW",
  "RETScreen 10000kW",
  "RETScreen 100000kW",
  "RETScreen 1000000kW",
  "RETScreen Tracking 10kW",
  "RETScreen Tracking 100kW",
  "RETScreen Tracking 1000kW",
  "RETScreen Tracking 10000kW",
  "RETScreen Tracking 100000kW",
  "RETScreen Tracking 1000000kW"
];