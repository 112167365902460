import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Card } from '@themesberg/react-bootstrap';
import styled from 'styled-components';
import { Transition } from 'react-transition-group';
import TextAreaWithLineNumbers from './TextAreaWithLineNumbers'; // Adjust the import path as necessary

const FadeDiv = styled.div`
  transition: 0.5s;
  opacity: ${({ state }) => (state === "entered" ? 1 : 0)};
  display: ${({ state }) => (state === "exited" ? "none" : "block")};
`;

const FadeTransition = ({ children, ...rest }) => (
  <Transition {...rest}>
    {state => <FadeDiv state={state}>{children}</FadeDiv>}
  </Transition>
);

const EVForm = ({ values, handleChange, handleBlur, handleFocus }) => {
  const [smartCharging, setSmartCharging] = useState(values.smartCharging);

  useEffect(() => {
    setSmartCharging(values.smartCharging);
  }, [values.smartCharging]);

  const handleSmartChargingChange = (event) => {
    const { value } = event.target;
    setSmartCharging(value);
    handleChange(event);
  };

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <Form id="evForm" noValidate>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>EV</Form.Label>
                <div>
                  <Form.Check
                    inline
                    label="Yes"
                    type="radio"
                    name="EV"
                    value="1"
                    checked={values.EV === '1'}
                    onChange={handleChange}
                  />
                  <Form.Check
                    inline
                    label="No"
                    type="radio"
                    name="EV"
                    value="0"
                    checked={values.EV === '0'}
                    onChange={handleChange}
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>
          <FadeTransition in={values.EV === '1'}>
            <div>
              <Row>
                <Col md={6} className="mb-3">
                  <Form.Group>
                    <Form.Label>Smart Charging</Form.Label>
                    <div>
                      <Form.Check
                        inline
                        label="Yes"
                        type="radio"
                        name="smartCharging"
                        value="1"
                        checked={smartCharging === '1'}
                        onChange={handleSmartChargingChange}
                      />
                      <Form.Check
                        inline
                        label="No"
                        type="radio"
                        name="smartCharging"
                        value="0"
                        checked={smartCharging === '0'}
                        onChange={handleSmartChargingChange}
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <FadeTransition in={smartCharging === '1'}>
                <div>
                  <Row>
                    <Col md={6} className="mb-3">
                      <Form.Group>
                        <Form.Label>Number of Vehicles</Form.Label>
                        <Form.Control
                          type="text"
                          value={values.numberOfVehicles}
                          onChange={handleChange}
                          name="numberOfVehicles"
                          placeholder="uds."
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-3">
                      <Form.Group>
                        <Form.Label>Number of Charging Points</Form.Label>
                        <Form.Control
                          type="text"
                          value={values.numberOfChargingPoints}
                          onChange={handleChange}
                          name="numberOfChargingPoints"
                          placeholder="uds."
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} className="mb-3">
                      <Form.Group>
                        <Form.Label>Maximum Charging Power of the EV Battery</Form.Label>
                        <Form.Control
                          type="text"
                          value={values.maxChargingPowerEVBattery}
                          onChange={handleChange}
                          name="maxChargingPowerEVBattery"
                          placeholder="kW"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-3">
                      <Form.Group>
                        <Form.Label>Maximum Power of the Charging Point</Form.Label>
                        <Form.Control
                          type="text"
                          value={values.maxPowerChargingPoint}
                          onChange={handleChange}
                          name="maxPowerChargingPoint"
                          placeholder="kW"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} className="mb-3">
                      <Form.Group>
                        <Form.Label>Maximum Power of the Charging Station</Form.Label>
                        <Form.Control
                          type="text"
                          value={values.maxPowerChargingStation}
                          onChange={handleChange}
                          name="maxPowerChargingStation"
                          placeholder="kW"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} className="mb-3">
                      <Form.Group>
                        <Form.Label style={{ fontSize: '1.25rem' }}>Vehicles Arrivals</Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} className="mb-3">
                      <Form.Group>
                        <Form.Label>Probability Distribution Arrivals</Form.Label>
                        <Form.Select
                          value={values.probDistArrivals}
                          onChange={handleChange}
                          name="probDistArrivals"
                        >
                          <option value="">Please select</option>
                          <option value="1">Normal</option>
                          <option value="0">Uniform</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-3">
                      <Form.Group>
                        <Form.Label>Days</Form.Label>
                        <Form.Select
                          value={values.days}
                          onChange={handleChange}
                          name="days"
                        >
                          <option value="">Please select</option>
                          <option value="todos los dias">todos los dias</option>
                          <option value="Lu-Vi">Lu-Vi</option>
                          <option value="fin de semana">fin de semana</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} className="mb-3">
                      <Form.Group>
                        <Form.Label>1st Parameter</Form.Label>
                        <Form.Control
                          type="text"
                          value={values.param1Arrivals}
                          onChange={handleChange}
                          name="param1Arrivals"
                          placeholder=""
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-3">
                      <Form.Group>
                        <Form.Label>2nd Parameter</Form.Label>
                        <Form.Control
                          type="text"
                          value={values.param2Arrivals}
                          onChange={handleChange}
                          name="param2Arrivals"
                          placeholder=""
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} className="mb-3">
                      <Form.Group>
                        <Form.Label style={{ fontSize: '1.25rem' }}>Parked Time</Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} className="mb-3">
                      <Form.Group>
                        <Form.Label>Probability Distribution</Form.Label>
                        <Form.Select
                          value={values.probDistParked}
                          onChange={handleChange}
                          name="probDistParked"
                        >
                          <option value="">Please select</option>
                          <option value="1">Normal</option>
                          <option value="0">Uniform</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-3">
                      <Form.Group>
                        <Form.Label>1st Parameter </Form.Label>
                        <Form.Control
                          type="text"
                          value={values.param1Parked}
                          onChange={handleChange}
                          name="param1Parked"
                          placeholder=""
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} className="mb-3">
                      <Form.Group>
                        <Form.Label>2nd Parameter </Form.Label>
                        <Form.Control
                          type="text"
                          value={values.param2Parked}
                          onChange={handleChange}
                          name="param2Parked"
                          placeholder=""
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </FadeTransition>
              <FadeTransition in={smartCharging === '0'}>
                <div>
                  <Row>
                    <Col md={12} className="mb-3">
                      <Form.Group>
                        <Form.Label>Time Series Data</Form.Label>
                        <TextAreaWithLineNumbers
                          value={values.timeSeries || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          onFocus={handleFocus}
                          placeholder="Copy and Paste Time Series Data Here"
                          name="timeSeries"
                        />
                        <Form.Control.Feedback type="invalid">
                          Time series data is required
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </FadeTransition>
            </div>
          </FadeTransition>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default EVForm;